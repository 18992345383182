import axios from "axios";
import { useAuthStore } from "@/stores/authStore";
import { encryptStorage } from "./encryptLocalStorage";
import { router } from "@/routes";
import { useToast } from "vue-toastification";
import allStore from "@/stores/allStore";
import { ROUTER_PUBLIC } from "@/useConfig";
import i18n from "@/langs/i18n";
import authService from "@/services/auth.service";
const toast$ = useToast();
const Api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

Api.interceptors.request.use(
  (config) => {
    const authStore = encryptStorage.getItem("auth");
    if (authStore) {
      if (authStore.accessToken) {
        config.headers["Authorization"] = "Bearer " + authStore.accessToken;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  async (config) => {
    let response = config.data;
    if (router.currentRoute.value.name != ROUTER_PUBLIC) {
      if (response.success == false && response.statusCode == 401) {
        let authStore = useAuthStore();
        encryptStorage.removeItem("auth");
        // authStore.resetAuth();
        allStore.logout();
        router.push({
          name: "login",
          query: {
            type: "timeout",
            backURL: router.currentRoute.value.name != "login" ? router.currentRoute.value.name : "",
          },
        });

        // const refreshTokenRequest = await authService.refresh();
        // if (refreshTokenRequest.success) {
        //   const authStore = encryptStorage.getItem("auth");
        //   authStore.accessToken = refreshTokenRequest.accessToken;
        //   encryptStorage.setItem("auth",authStore);
     
        // } else {
        //   let authStore = useAuthStore();
        //   encryptStorage.removeItem("auth");
        //   // authStore.resetAuth();
        //   allStore.logout();
        //   router.push({
        //     name: "login",
        //     query: {
        //       type: "timeout",
        //       backURL: router.currentRoute.value.name != "login" ? router.currentRoute.value.name : "",
        //     },
        //   });
        // }
      }
    }
    return config?.data || { success: false, statusCode: 401 };
  },
  (error) => {
    try {
      toast$.error(error?.response.data.message ? error?.response.data.message : i18n.global.t("someThingWrong"), { timeout: 4000 });
      return { success: false };
    } catch (error) {
      toast$.error(i18n.global.t("someThingWrong"), { timeout: 4000 });
      return { success: false };
    }
  }
);

export default Api;
