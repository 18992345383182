<template>
  <MoneyUI>
    <MoneyMain  v-if="auth$.checkRole(ROLE_TRANSACTION_LIST)"></MoneyMain>
  </MoneyUI>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import MoneyMain from "@/components/money/MoneyMain.vue";
import { useAuthStore } from "@/stores/authStore";
export default {
  name: "MoneyPage",
  setup: () => ({
    auth$: useAuthStore(),
  
  }),
  components: {
    MoneyUI,MoneyMain
  },
  created() {},
  mounted() {},
};
</script>
<style lang=""></style>
