<template lang="">
  <v-container :fluid="true">
    <v-row v-if="!loading" class="text-center mt-5 mx-auto">
      <v-col v-if="auth$.checkRole(ROLE_GOLDEN_PRICE)" lg="6" ref="componentPrice" cols="12" class="mx-auto">
        <v-card :class="goldenStore.selecting ? 'border-xl border-error' : ''">
          <v-card-title>
            <v-row>
              <v-col cols="12" lg="4" class="text-left">
                <v-btn :loading="loadingSJCCrawl" @click="callCrawlSJCData()" variant="text" class="text-left hover-pointer">
                  <v-tooltip activator="parent" location="end">
                    {{ $t("goldenTTRefresh") }}
                  </v-tooltip>
                  <v-icon size="x-large"> mdi mdi-refresh</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" lg="4">
                Giá Vàng Hôm Nay <span> Nguồn: SJC</span>
                <br />
                <div class="text-body-2 text-green-darken-3 font-weight-medium">{{ sjcDate }}</div>
              </v-col>
              <v-col cols="12" lg="4">
                <v-btn v-if="goldenStore.selecting" @click="goldenStore.setGoldType(false)" class="float-right ml-3" size="small" icon>
                  <v-icon color="white"> mdi mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-table hover :class="goldenStore.selecting ? 'hover-pointer' : ''">
            <thead>
              <tr>
                <th class="text-center font-weight-medium">{{ $t("goldenTFType") }}</th>
                <th class="text-center font-weight-medium">{{ $t("goldenBuy") }}</th>
                <th class="text-center font-weight-medium">{{ $t("goldenSell") }}</th>
              </tr>
            </thead>
            <tbody class="text-base">
              <tr
                @click="selectGoldType(item)"
                v-for="item in sjc"
                :key="item.id"
                :class="goldenStore.selectedGoldType.type == item.name && displayForm ? 'select-gold-type ' : ''"
              >
                <td class="font-weight-medium">{{ item.name }}</td>
                <td class="font-weight-medium">
                  <span class="hover-pointer d-inline-flex">
                    {{ formatNumber(item.buyValue) }}
                    <v-tooltip v-if="item.buyProfit" activator="parent" location="start">
                      <span class="white"
                        >{{ formatNumber(item.beforeBuyDay) }}/{{ item.buyProfit > 0 ? "+" : "" }}{{ formatNumber(item.buyProfit) }}
                      </span>
                    </v-tooltip>

                    <v-icon
                      v-if="item.buyProfit"
                      class="mb-1"
                      :class="
                        item.buyProfit < 0
                          ? 'text-red-darken-3 blink-appear font-weight-bold'
                          : 'text-light-green-darken-3 blink-appear font-weight-bold'
                      "
                    >
                      {{ item.buyProfit > 0 ? "mdi mdi-trending-up" : "mdi mdi-trending-down" }}
                    </v-icon>
                  </span>
                </td>
                <td class="text-blue-darken-1 font-weight-medium">
                  <span class="hover-pointer d-inline-flex">
                    {{ formatNumber(item.sellValue) }}
                    <v-tooltip v-if="item.sellProfit != 0" activator="parent" location="start">
                      <span class="white"
                        >{{ formatNumber(item.beforeSellDay) }}/{{ item.sellProfit > 0 ? "+" : "" }}{{ formatNumber(item.sellProfit) }}</span
                      >
                    </v-tooltip>
                    <v-icon
                      v-if="item.sellProfit"
                      class="mb-1"
                      :class="
                        item.sellProfit < 0
                          ? 'text-red-darken-3 blink-appear font-weight-bold'
                          : 'text-light-green-darken-3 blink-appear font-weight-bold'
                      "
                    >
                      {{ item.sellProfit > 0 ? "mdi mdi-trending-up" : "mdi mdi-trending-down" }}
                    </v-icon>
                  </span>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>

      <v-col v-if="auth$.checkRole(ROLE_GOLDEN_PRICE)" cols="12" lg="6">
        <GoldenChart :temp="false" :goldenTypes="goldenTypes" v-if="!loadingGolden"></GoldenChart>
        <GoldenChart :temp="true" :class="loadingGolden ? '' : 'd-none'"></GoldenChart>
      </v-col>

      <v-slide-x-transition>
        <v-col v-if="auth$.checkRole(ROLE_GOLDEN_ADD) || auth$.checkRole(ROLE_GOLDEN_EDIT)" lg="10" xs="12" sm="12" md="8" class="mx-auto">
          <GoldenFrom
            @createdSuccess="createdSuccess()"
            @closeForm="closeForm()"
            @updateSuccess="updateSuccess()"
            :displayForm="displayForm"
            :isEditing="isEditing"
            :edit="edit"
          ></GoldenFrom>
        </v-col>
      </v-slide-x-transition>
      <v-col v-if="auth$.checkRole(ROLE_GOLDEN_LIST)" cols="12" class="mx-auto">
        <v-card :disabled="goldenStore.selecting" class="mx-auto">
          <v-card-title>
            <v-btn
              v-if="auth$.checkRole(ROLE_GOLDEN_ADD)"
              class="float-right"
              @click="showCreateForm()"
              size="small"
              :disabled="state != ''"
              icon
              :color="!displayForm ? 'success' : isEditing ? 'success' : 'secondary'"
            >
              <v-icon> {{ !displayForm ? "mdi mdi-plus" : "mdi mdi-arrow-up" }}</v-icon>
              <template v-if="isEditing"> | <v-icon>mdi mdi-plus</v-icon> </template>
            </v-btn>

            <v-btn
              variant="outlined"
              class="float-right mx-2 hover-pointer"
              size="small"
              icon
              :loading="loadingUpdateGoldens"
              @click="updateAllCurrentBuy()"
            >
              <v-icon color=""> mdi mdi-refresh</v-icon>
              <v-tooltip activator="parent" location="end">
                {{ $t("goldenTTRefreshGolden") }}
              </v-tooltip>
            </v-btn>
          </v-card-title>
          <v-table hover>
            <thead>
              <tr>
                <!-- <th class="text-center font-weight-medium"></th> -->
                <th class="text-center font-weight-medium"></th>
                <th class="text-center font-weight-medium">{{ $t("goldenTFCreatedAt") }}</th>
                <th class="text-center font-weight-medium">{{ $t("signUpName") }}</th>
                <th class="text-center font-weight-medium">{{ $t("goldenQuantity") }}</th>
                <th class="text-center font-weight-medium">{{ $t("goldenTFType") }}</th>
                <th class="text-center font-weight-medium">{{ $t("goldenTFRootBuy") }}</th>
                <th class="text-center font-weight-medium">{{ $t("goldenTFRootSell") }}</th>
                <th @click="showDialogUpdateAll('currentBuy')" class="text-center font-weight-medium hover-pointer save-active-hover text-cyan">
                  {{ $t("goldenTFCurrentBuy") }}
                </th>
                <th class="text-center font-weight-medium">
                <!-- <th @click="showDialogUpdateAll('currentSell')" class="text-center font-weight-medium hover-pointer save-active-hover text-cyan"> -->
                  {{ $t("goldenTFCurrentSell") }}
                </th>
                <th class="text-center font-weight-medium">{{ $t("WalletTableTotal") }}</th>
                <th class="text-center font-weight-medium">{{ $t("GoldenTableProfit") }}</th>
                <th class="text-center font-weight-medium"></th>
              </tr>
            </thead>
            <tbody class="text-base">
              <tr
                bg-color="primary"
                class=""
                :style="configStore$.theme == this.DARK_THEME ? 'background-color:rgb(242 242 242 / 75%)' : 'background-color:rgb(155 225 163 / 49%)'"
              >
                <td colspan="3"></td>
                <td class="text-blue font-weight-bold">{{ calculating.quantity / 10 }} - {{ calculating.quantity }}</td>
                <td class="text-deep-purple-darken-2 font-weight-bold">{{ $t("WalletTableTotal") }}:</td>
                <!-- <td class="text-light-blue-darken-4 font-weight-bold">{{ formatNumber(calculating.rootSell) }}</td>
                <td class="text-orange-accent-4 font-weight-bold">{{ formatNumber(calculating.rootBuy) }}</td> -->
                <td></td>
                <td></td>
                <td class="text-green-darken-3 font-weight-bold"></td>
                <td class="text-red-accent-4 font-weight-bold"></td>
                <td class="text-red-accent-4 font-weight-bold">{{ formatNumber(calculating.total) }}</td>
                <td
                  class="pa-1"
                  :class="calculating.profit <= 0 ? 'text-red-darken-3 blink font-weight-bold' : 'text-light-green-darken-3 font-weight-bold'"
                >
                  <span class="hover-pointer d-inline-flex">
                    {{ formatNumber(calculating.profit) }}
                    <v-tooltip v-if="calculating.differentValue != 0" activator="parent" location="start">
                      <span class="white">{{ calculating.differentValue > 0 ? "+" : "" }}{{ formatNumber(calculating.differentValue) }}</span>
                    </v-tooltip>
                    <v-icon
                      v-if="calculating.differentValue != 0"
                      class="mb-1"
                      :class="
                        calculating.differentValue < 0
                          ? 'text-red-darken-3 blink-appear font-weight-bold'
                          : 'text-light-green-darken-3 blink-appear font-weight-bold'
                      "
                    >
                      {{ calculating.differentValue > 0 ? "mdi mdi-trending-up" : "mdi mdi-trending-down" }}
                    </v-icon>
                  </span>

                  <!-- ({{calculating.differentValue}}) -->
                </td>
                <td
                  class="pa-2"
                  :class="
                    calculating.final <= calculating.total
                      ? 'text-red-darken-3 blink font-weight-bold'
                      : 'text-light-blue-darken-3  font-weight-black'
                  "
                >
                  <span class="hover-pointer d-inline-flex">
                    {{ formatNumber(calculating.final) }}
                    <v-tooltip v-if="calculating.finalDifferent != 0" activator="parent" location="start">
                      <span class="white">{{ calculating.finalDifferent > 0 ? "+" : "" }} {{ formatNumber(calculating.finalDifferent) }}</span>
                    </v-tooltip>
                    <v-icon
                      v-if="calculating.finalDifferent != 0"
                      class="mb-1"
                      :class="
                        calculating.finalDifferent < 0
                          ? 'text-red-darken-3 blink-appear font-weight-bold'
                          : 'text-light-green-darken-3 blink-appear font-weight-bold'
                      "
                    >
                      {{ calculating.finalDifferent > 0 ? "mdi mdi-trending-up" : "mdi mdi-trending-down" }}
                    </v-icon>
                  </span>
                </td>
              </tr>
              <tr v-for="item in goldens" :key="item.id">
                <td stlye="display: inline-block">
                  <v-checkbox @click="updateCalculated(item)" v-model="item.calculated" color="info"> </v-checkbox>
                </td>
                <td class="pa-0">
                  <span class="d-none d-lg-flex" :class="!item.calculated ? 'text-red-accent-4' : ''">
                    {{ item.createdAt }}
                  </span>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }} Chỉ</td>
                <td>{{ item.type }}</td>
                <td size="small">{{ formatNumber(item.rootBuy) }}</td>
                <td>{{ formatNumber(item.rootSell) }}</td>
                <td :class="item.rootBuy >= item.currentBuy ? 'text-red-darken-3 blink font-weight-bold' : 'text-light-green-darken-3'">
                  {{ formatNumber(item.currentBuy) }}
                </td>
                <td :class="item.rootSell >= item.currentSell ? 'text-red-darken-3 blink font-weight-bold' : 'text-light-green-darken-3'">
                  {{ formatNumber(item.currentSell) }}
                </td>
                <td class="text-light-blue-accent-4 font-weight-bold">{{ formatNumber(item.total) }}</td>
                <td :class="item.profit <= 0 ? 'text-red-darken-3  blink font-weight-bold' : 'text-light-green-darken-3'">
                  {{ formatNumber(item.profit) }}
                </td>
                <td class="pa-1">
                  <v-btn
                    v-if="auth$.checkRole(ROLE_GOLDEN_DELETE)"
                    variant="outlined"
                    class="ma-1"
                    color="error"
                    @click="remove(item)"
                    icon="mdi-delete"
                    size="small"
                  ></v-btn>
                  <v-btn
                    v-if="auth$.checkRole(ROLE_GOLDEN_EDIT)"
                    :variant="edit.id == item.id ? 'flat' : 'outlined'"
                    @click="showEditForm(item)"
                    variant="text"
                    color="info"
                    icon="mdi-pencil"
                    class="ma-1"
                    size="small"
                  ></v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
      <v-col v-if="auth$.checkRole(ROLE_GOLDEN_TOTAL) || auth$.checkRole(ROLE_GOLDEN_PROFIT)" cols="12">
        <GoldenStoreChart :temp="false" v-if="!loadingGolden"></GoldenStoreChart>
        <GoldenStoreChart :temp="true" :class="loadingGolden ? '' : 'd-none'"></GoldenStoreChart>
      </v-col>
    </v-row>

    <v-progress-circular
      style="top: 40%"
      :style="!this.display$.lgAndUp.value ? ' left:42%' : 'left:48%'"
      class="d-block ma-auto mb-3 mt-10 position-absolute"
      :size="100"
      v-else
      indeterminate
    ></v-progress-circular>

    <v-dialog class="mt-20" v-model="isDiaglog" max-width="500">
      <v-card class="pa-2">
        <v-card-title>
          <div class="d-flex justify-space-between">
            <div class="justify-space-center">
              <span
                ><span>
                  {{ $t("goldenUpdateAll") }} {{ dialogText == "currentBuy" ? $t("goldenTFCurrentBuy") : $t("goldenTFCurrentSell") }}
                </span></span
              >
            </div>
            <v-btn variant="text" class="float-right" color="secondary" @click="isDiaglog = false" icon="mdi mdi-close"></v-btn>
          </div>
          <v-divider></v-divider>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="updateAll()">
            <!-- <v-text-field
              name="title"
              class="text-left"
              v-model="v$.formUpdateAll.value.$model"
              :error-messages="
                v$.formUpdateAll.value.required.$invalid && v$.formUpdateAll.value.$dirty
                  ? $t('validRequired', { field: dialogText == 'currentBuy' ? $t('goldenTFCurrentBuy') : $t('goldenTFCurrentSell') })
                  : ''
              "
              @input="v$.formUpdateAll.value.$touch()"
              @blur="v$.formUpdateAll.value.$touch()"
              block
              :label="dialogText == 'currentBuy' ? $t('goldenTFCurrentBuy') : $t('goldenTFCurrentSell') + '*'"
            ></v-text-field> -->

            <div class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused hover-border-primary">
              <div class="v-input__control">
                <div class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light" role="textbox">
                  <div class="v-field__field" data-no-activator="">
                    <input
                      :style="formUpdateAll.value > 0 ? 'color:green' : formUpdateAll.value == 0 ? '' : 'color:red'"
                      name="money_total"
                      size="1"
                      type="text"
                      v-model="amountUI"
                      class="v-field__input v-field-focused"
                    />
                  </div>
                  <div class="v-field__outline">
                    <div class="v-field__outline__start hover-border-primary"></div>
                    <div class="v-field__outline__notch">
                      <label class="v-label v-field-label v-field-label--floating" aria-hidden="true" for="input-36">{{
                        dialogText == "currentBuy" ? $t("goldenTFCurrentBuy") : $t("goldenTFCurrentSell")
                      }}</label>
                    </div>
                    <div class="v-field__outline__end hover-border-primary"></div>
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <br />
        <v-card-actions>
          <v-btn variant="text" @click="isDiaglog = false" class="float-right mr-2" color="secondary">
            <v-icon>mdi mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="updateAll()" :disabled="v$.formUpdateAll.value.required.$invalid || this.amountUI == 0" variant="flat" color="info">
            <v-icon>mdi mdi-pencil</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import GoldenFrom from "./GoldenFrom.vue";
import { useDisplay, useTheme } from "vuetify";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toastification";
import goldenServices from "@/services/golden.service";
import { useGoldenStore } from "@/stores/goldenStore";
const SJC = "sjc";
const PNJ = "pnj";
import GoldenChart from "./GoldenChart.vue";
import GoldenStoreChart from "./GoldenStoreChart.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { number } from "echarts";

export default {
  name: "MainGolden",
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    v$: useVuelidate(),
    toast$: useToast(),
    goldenStore: useGoldenStore(),
    display$: useDisplay(),
  }),
  components: {
    GoldenFrom,
    GoldenChart,
    GoldenStoreChart,
  },
  data: () => ({
    dialogText: "",
    formUpdateAll: {
      value: "",
    },
    displayForm: false,
    goldens: [],
    amountUI: "",
    edit: "",
    isEditing: false,
    loading: true,
    state: "",
    golds: [],
    goldUpdated: "",
    isDiaglog: false,
    defaultSource: "sjc",
    pnj: [],
    pnjDate: [],
    sjc: [],
    sjcDate: "",
    loadingSJCCrawl: false,
    loadingPNJCrawl: false,
    loadingUpdateGoldens: false,
    historyGolden: "",
    loadingGolden: true,
    goldenTypes: [],
    updatedDateProfit: "",
    heightChartPriceRender: 600,
    calculating: {
      rootBuy: 0,
      quantity: 0,
      rootSell: 0,
      profit: 0,
      total: 0,
      final: 0,
      state: false,
      differentValue: 0,
      finalDifferent: 0,
    },
  }),
  validations() {
    return {
      formUpdateAll: {
        value: {
          required,
          number,
        },
      },
    };
  },
  created() {},
  mounted() {
    this.getGoldPriceList({ source: [SJC] });
    this.goldenLists();
  },
  methods: {
    showDialogUpdateAll(type) {
      this.amountUI = "";
      this.isDiaglog = !this.isDiaglog;

      this.dialogText = type;
      this.formUpdateAll.value = "";
      this.amountUI = "";
      this.v$.$reset();
    },
    async updateAll() {
      this.$swal({
        title: this.$t("swalSureField", { action: this.$t("edit") + " " + this.$t("categoryAll").toLowerCase() }),
        text: `${this.dialogText == "currentBuy" ? this.$t("goldenTFCurrentBuy") : this.$t("goldenTFCurrentSell")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("no"),
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const request = await goldenServices.updateAll(this.dialogText, this.formUpdateAll.value);
          if (request.success) {
            this.isDiaglog = false;
            this.formUpdateAll.value = "";
            this.amountUI = "";
            this.v$.$reset();
            this.goldenLists();
            this.toast$.success(this.$t("fieldSuccess", { field: this.$t("goldenStore"), action: this.$t("actionUpdated") }));
          }
        }
      });
    },
    async callCrawlPNJData() {
      this.loadingPNJCrawl = true;
      await goldenServices.crawlPNJData();
      await this.getGoldPriceList({ source: [PNJ] });
      this.loadingPNJCrawl = false;
    },
    async callCrawlSJCData() {
      this.loadingSJCCrawl = true;
      await goldenServices.crawlSJCData();
      await this.getGoldPriceList({ source: [SJC] });
      this.loadingSJCCrawl = false;
    },
    async getGoldPriceList(sources) {
      var request = await goldenServices.prices({ ...sources });
      if (request.success) {
        let data = [];
        data = request.prices;
        if (sources.source.includes(SJC)) {
          this.sjc = data.filter((x) => x.source == SJC);
          this.sjcDate = request.date.sjcDate;
        }
        if (sources.source.includes(PNJ)) {
          this.pnj = data.filter((x) => x.source == PNJ);
          this.pnjDate = request.date.pnjDate;
        }

        if (request.priceHistories && request.prices) {
          request.prices.map((item) => {
            let findHistories = request.priceHistories.find((x) => x.name == item.name);
            if (findHistories) {
              item.beforeSellDay = findHistories.sellValue;
              item.sellProfit = item.sellValue - findHistories.sellValue;
              item.beforeBuyDay = findHistories.buyValue;
              item.buyProfit = item.buyValue - findHistories.buyValue;
            }
          });
        }
      } else {
        this.toast$.error("Failed fetch data golden prices, please try again later!");
      }
    },

    handleDataRaw() {},
    async goldenLists() {
      this.loadingGolden = true;
      var request = await goldenServices.goldens();
      if (request.success) {
        this.goldens = request.goldens;
        let goldenTypes = [];
        this.goldenTypes = [];
        this.goldens.map((item) => {
          if (item.calculated) {
            goldenTypes.push(item.type);
            this.goldenTypes.push(item.type);
          }
        });
        goldenTypes = [...new Set(goldenTypes)];
        this.goldenTypes = [...new Set(this.goldenTypes)];
        this.goldenStore.arrayGoldeType = goldenTypes;
        this.historyGolden = request.histories;
        // this.updatedDateProfit = request.date_updated
        this.goldenStore.profitDate = request.date_updated;
      }
      this.calculatingValue();
      this.loading = false;
      this.loadingGolden = false;
    },
    updateGoldenType() {
      this.goldenTypes = [];
      this.goldens.map((item) => {
        if (item.calculated) {
          this.goldenTypes.push(item.type);
        }
      });
      this.goldenTypes = [...new Set(this.goldenTypes)];
      this.goldenStore.selectedGoldType = this.goldenTypes;
      this.$forceUpdate();
    },
    selectGoldType(item) {
      item.type = item.name;
      this.goldenStore.selectedGoldType = item;
      this.goldenStore.setGoldType(false);
    },
    showCreateForm() {
      if (this.displayForm == true) {
        if (this.isEditing) {
          this.isEditing = false;
          this.edit = "";
          this.goldenStore.selectedGoldType = "";
        } else {
          this.displayForm = false;
        }
      } else {
        this.displayForm = true;
      }
    },
    showEditForm(item) {
      if (item.id == this.edit.id) {
        this.isEditing = false;
        this.displayForm = false;
        this.edit = "";
        this.goldenStore.selectedGoldType = "";
      } else {
        this.displayForm = true;
        this.isEditing = true;
        this.edit = item;
        this.goldenStore.selectedGoldType = item;
      }
    },
    createdSuccess() {
      this.displayForm = false;
      this.goldenStore.setGoldType(false);
      this.goldenStore.selectedGoldType = "";
      this.isEditing = false;
      this.edit = "";
      this.goldenLists();
      this.heightChartPriceRender = this.$refs.componentPrice.$vuetify.display.height;

      // this.updateGoldenType();
    },
    closeForm() {
      this.displayForm = false;
      this.goldenStore.setGoldType(false);
      this.edit = "";
      this.isEditing = false;
      this.goldenStore.selectedGoldType = "";
    },
    async remove(item) {
      this.$swal({
        title: this.$t("swalSureField", { action: this.$t("delete") }),
        text: `${this.$t("goldenStore")} ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("no"),
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var remove = await goldenServices.delete(item.id);
          if (remove.success) {
            this.goldenLists();
            this.updateGoldenType();
            this.toast$.success(this.$t("fieldSuccess", { field: this.$t("goldenStore"), action: this.$t("actionRemove") }));
          }
        }
      });
    },
    async updateCalculated(item) {
      this.goldenTypes = [];
      this.loadingGolden = true;

      this.goldens.map((gold) => {
        if (item.id == gold.id) {
          gold.calculated = !item.calculated;
        }
        if (gold.calculated == true) {
          this.goldenTypes.push(gold.type);
        }
      });
      this.goldenTypes = [...new Set(this.goldenTypes)];
      this.goldenStore.selectedGoldType = this.goldenTypes;
      this.$forceUpdate();
      this.calculatingValue();
      await goldenServices.updateField(item.id, "calculated", item.calculated);
      this.loadingGolden = false;
    },
    calculatingValue() {
      this.calculating.quantity = 0;
      this.calculating.rootSell = 0;
      this.calculating.rootBuy = 0;
      this.calculating.profit = 0;
      this.calculating.total = 0;
      this.calculating.final = 0;
      this.calculating.differentValue = 0;
      this.calculating.finalDifferent = 0;
      this.goldens.map((item) => {
        if (item.calculated) {
          this.calculating.rootSell = this.calculating.rootSell + item.rootSell;
          this.calculating.rootBuy = this.calculating.rootBuy + item.rootBuy;
          this.calculating.profit = this.calculating.profit + item.profit;
          this.calculating.total = this.calculating.total + item.total;
          this.calculating.quantity = this.calculating.quantity + parseInt(item.quantity);
        }
      });
      this.calculating.final = this.calculating.total + this.calculating.profit;
      if (this.historyGolden) {
        this.calculating.differentValue = this.calculating.profit - this.historyGolden.profit;
        this.calculating.finalDifferent = this.calculating.final - this.historyGolden.final;
      }
    },
    async updateAllCurrentBuy() {
      this.loadingUpdateGoldens = true;

      var updateGoldens = await goldenServices.updateGoldenCurrentProces();
      if (updateGoldens.success) {
        this.goldenLists();
        this.getGoldPriceList({ source: [SJC] });
      }
      this.loadingUpdateGoldens = false;
    },
  },
  watch: {
    "goldenStore.source": function (new_value, old_value) {
      if (new_value == "pnj") {
        this.golds = this.pnj;
        this.goldUpdated = this.pnjDate;
      } else {
        this.golds = this.sjc;
        this.goldUpdated = this.sjcDate;
      }
    },
    amountUI: function (new_value) {
      this.amountUI = this.formatCurrency(new_value);
      this.formUpdateAll.value = this.removeAllSpecialCharacter(this.amountUI);
    },
  },
  computed: {},
};
</script>
<style>
.v-dialog__content {
  align-items: flex-end;
  justify-content: flex-end;
}
.center-screen {
  /* display: flex;
  justify-content: center;
  align-items: center;
  top: 150px;
  text-align: center; */
}
</style>
