<template lang="">
  <v-col cols="12">
    <v-card>
      <v-card-title>Quyền</v-card-title>
      <v-table hover block>
        <thead>
          <tr>
            <th class="text-left">Icon</th>
            <th class="text-left">Name</th>
            <th class="text-left">Phone</th>
            <th class="text-left">Email</th>
            <th class="text-left">Role</th>
            <th class="text-left">Created At</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Icon</td>
            <td>Icon</td>
            <td>Icon</td>
            <td>Icon</td>
            <td>Icon</td>
            <td>Icon</td>
            <td>Icon</td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </v-col>
</template>
<script>
import userService from "@/services/admins/user.service";
import { throwStatement } from "@babel/types";
import LoadingAdmin from "../elements/LoadingAdmin.vue";
import permissionService from "@/services/permission.service";

export default {
  name: "Role",
  components: {
    LoadingAdmin,
  },
  data() {
    return {
      flag: false,
      permissions: [],
    };
  },
  created() {},
  mounted() {
 
  },
  methods: {
   
  },
  watch: {},
};
</script>
<style lang=""></style>
