export const Modules = [
  { id: 1, name: "Trang Chủ", name_en: "Home", code: "home", icon: "mdi-home", page_url: "home" },
  { id: 2, name: "Vàng", name_en: "Goldens", code: "golden", icon: "mdi-gold", page_url: "golden" },
  { id: 3, name: "Ví", name_en: "Wallets", code: "wallet", icon: "mdi-wallet", page_url: "wallet" },
  { id: 4, name: "Giao Dịch", name_en: "Transactions", code: "money", icon: "mdi-currency-usd", page_url: "money" },
  { id: 5, name: "Quản Trị", name_en: "Administrator", code: "system", icon: "mdi mdi-crown-outline", page_url: "system" },
  { id: 6, name: "Tài khoản", name_en: "Account", code: "account", icon: "mdi-account", page_url: "account" },
];

export const ModuleLevel2 = [
  //wallets
  { id: 101, name: "Danh sách", name_en: "List Wallet", code: "wallet_list", icon: "mdi-star", page_url: "wallet" },
  { id: 102, name: "Sửa ví", name_en: "Edit wallets", code: "wallet_edit", icon: "mdi-pencil", page_url: "wallet" },
  { id: 103, name: "Thêm  ví", name_en: "Add wallets", code: "wallet_add", icon: "mdi-plus", page_url: "wallet" },
  { id: 104, name: "Xóa  ví", name_en: "Delete wallets", code: "wallet_delete", icon: "mdi-delete", page_url: "wallet" },

  //goldens
  { id: 201, name: "Xem giá vàng", name_en: "Golden Prices", code: "golden_prices", icon: "mdi-chart-line", page_url: "golden" },
  { id: 201, name: "Xem tổng tiền", name_en: "Golden Total", code: "golden_total", icon: "mdi-chart-line", page_url: "golden" },
  { id: 201, name: "Xem lợi nhuận", name_en: "Golden profit", code: "golden_profit", icon: "mdi-chart-line", page_url: "golden" },
  { id: 200, name: "Tạo Lưu Trữ", name_en: "Golden Create", code: "golden_add", icon: "mdi-plus", page_url: "golden" },
  { id: 200, name: "Danh Sách", name_en: "Golden list", code: "golden_list", icon: "mdi-view-list", page_url: "golden" },
  { id: 200, name: "Xóa lưu trữ", name_en: "Golden delete", code: "golden_delete", icon: "mdi-delete", page_url: "golden" },
  { id: 200, name: "Sửa lưu trữ", name_en: "Golden edit", code: "golden_edit", icon: "mdi-pencil", page_url: "golden" },

  //transactions
  { id: 300, name: "Danh sách ví", name_en: "Transactions", code: "transaction_list", icon: "mdi-view-list", page_url: "money" },
  { id: 301, name: "Thêm giao dịch", name_en: "Add Transactions", code: "transaction_add", icon: "mdi-plus", page_url: "money" },
  { id: 303, name: "Sửa giao dịch", name_en: "Edit Transactions", code: "transaction_edit", icon: "mdi-pencil", page_url: "money" },
  { id: 304, name: "Publish giao dịch", name_en: "Publish Transactions", code: "transaction_publish", icon: "mdi-earth", page_url: "money" },
  { id: 305, name: "Xóa giao dịch", name_en: "Delete Transactions", code: "transaction_delete", icon: "mdi-delete", page_url: "money" },

  //administrator
  { id: 900, name: "Quản lí Người Dùng", name_en: "Users", code: "system_user", icon: "mdi-mdi mdi-account-supervisor-outline", page_url: "user" },
  {
    id: 901,
    name: "Quản lí Danh Mục",
    name_en: "Categories",
    code: "system_category",
    icon: "mdi mdi-format-list-bulleted-type",
    page_url: "category",
  },
  { id: 902, name: "Quản lí Modules", name_en: "Modules", code: "system_module", icon: "mdi-view-module", page_url: "module" },
  { id: 903, name: "Quản lí Quyền", name_en: "Roles", code: "system_role", icon: "mdi-hand-back-left-off-outline", page_url: "role" },
  { id: 904, name: "Quản lí Icon", name_en: "Icon", code: "system_icon", icon: "mdi-image-area", page_url: "icon" },
];

export const ModuleLevel3 = [
  //users
  { id: 1001, name: "Thêm tài khoản", name_en: "Add Account", code: "system_user_add", icon: "mdi-plus", page_url: "user" },
  { id: 1002, name: "Xóa tài khoản", name_en: "Delete Account", code: "system_user_delete", icon: "mdi-delete", page_url: "user" },
  { id: 1003, name: "Sửa tài khoản", name_en: "Edit Account", code: "system_user_edit", icon: "mdi-pencil", page_url: "user" },
  { id: 1004, name: "Sửa quyền", name_en: "Edit Account Role", code: "system_user_role", icon: "mdi-hand-back-left", page_url: "user" },
  { id: 1005, name: "Xem mật khẩu", name_en: "View password", code: "system_user_password", icon: "mdi-eye", page_url: "user" },

  //icon
  { id: 1100, name: "Thêm icon", name_en: "Add Account", code: "system_icon_add", icon: "mdi-plus", page_url: "icon" },
  { id: 1101, name: "Xóa icon", name_en: "Delete Account", code: "system_icon_delete", icon: "mdi-delete", page_url: "icon" },
  { id: 1102, name: "Sửa icon", name_en: "Edit Account", code: "system_icon_edit", icon: "mdi-pencil", page_url: "icon" },

  //role
  { id: 1200, name: "Thêm quyền", name_en: "Add Role", code: "system_role_add", icon: "mdi-plus", page_url: "role" },
  { id: 1201, name: "Xóa quyền", name_en: "Delete Role", code: "system_role_delete", icon: "mdi-delete", page_url: "role" },
  { id: 1202, name: "Sửa quyền", name_en: "Edit Role", code: "system_role_edit", icon: "mdi-pencil", page_url: "role" },

  //modules
  { id: 1300, name: "Thêm module", name_en: "Add module", code: "system_module_add", icon: "mdi-plus", page_url: "module" },
  { id: 1301, name: "Thêm tính năng", name_en: "Add feature", code: "system_module_add_feature", icon: "mdi-plus", page_url: "module" },
  { id: 1302, name: "Thêm quyền", name_en: "Add Role", code: "system_module_add_role", icon: "mdi-plus", page_url: "module" },
  { id: 1303, name: "Xóa module", name_en: "Delete module", code: "system_module_delete", icon: "mdi-delete", page_url: "module" },
  { id: 1304, name: "Xóa tính năng", name_en: "Delete feature", code: "system_module_delete_feature", icon: "mdi-delete", page_url: "module" },
  { id: 1305, name: "Xóa quyền", name_en: "Delete Role", code: "system_module_delete_role", icon: "mdi-delete", page_url: "module" },
  { id: 1306, name: "Sửa module", name_en: "Edit module", code: "system_module_edit", icon: "mdi-pencil", page_url: "module" },
  { id: 1307, name: "Sửa tính năng", name_en: "Edit feature", code: "system_module_edit_feature", icon: "mdi-pencil", page_url: "module" },
  { id: 1308, name: "Sửa quyền", name_en: "Edit Role", code: "system_module_edit_role", icon: "mdi-pencil", page_url: "module" },
];
