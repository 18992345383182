import Api from "@/utils/axios";

export default {
  categories() {
    return Api.post("/categories", {});
  },
  transactions(query) {
    return Api.post("/transactions/transactions-by-wallet", { action: "transactions-by-wallet", ...query });
  },
  delete(id) {
    return Api.post("/transactions/delete", { transaction_id: id });
  },
  save(data) {
    return Api.request({
      method: "post",
      url: "/transactions/save",
      data: { action: "save", ...data },
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  update(data) {
    return Api.request({
      method: "post",
      url: "/transactions/update",
      data: { action: "update", ...data },
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
