<template>
  <div class="row">
    <Role v-if="$route.name == ROUTER_ADMIN_ROLE && auth$.checkRole(ROLE_ROLE_MAIN)"></Role>
    <router-view v-else></router-view>
  </div>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";

import Role from "@/pages/admins/roles/Role.vue";
import { useAuthStore } from "@/stores/authStore";
export default {
  name: "AdminPermissionPage",
  setup: () => ({
    auth$: useAuthStore(),
  }),
  components: {
    MoneyUI,
    Role,
  },
  data() {
    return {
      flag: false,
      permissions: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang=""></style>
