import { defineStore } from "pinia";
import { encryptStorage } from "@/utils/encryptLocalStorage";
import _ from "lodash";
import authService from "@/services/auth.service";
import allStore from "./allStore";
import { useCommonStore } from "./commonStore";
import { ROUTER_ADMIN } from "@/useConfig";
const nameStore = "auth";
const jsonConfigDefault = {
  saveRoute: "",
};
export const useAuthStore = defineStore("auth", {
  state: () => ({
    id: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).id : "",
    name: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).name : "",
    email: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).email : "",
    role: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).role : "",
    icon: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).icon : "",
    iconId: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).iconId : "",
    isLogin: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).isLogin : "",
    phone: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).phone : "",
    permission: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).permission : "",
    accessToken: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).accessToken : "",
    setting: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).setting : "",
    jsonConfig: encryptStorage.getItem(nameStore) ? encryptStorage.getItem(nameStore).jsonConfig : jsonConfigDefault,
    saveRoute: "",
    menus: [],
    roles: [],
    menuAdmin: [],
  }),

  actions: {
    async loadMe(user) {
      let _this = this;
      let me = user;
      let commonStore = useCommonStore();
      this.menus = [];
      this.roles = [];
      this.menuAdmin = [];
      if (me.success && me.user) {
        if (me.user.menus) {
          this.menus = me.user.menus;
          this.roles = me.user.roles;

          this.role = me.user.role;
          commonStore.currencies = me.currency;
          commonStore.icons = me.icon;
          if (this.menus.length > 0) {
            this.menus.map((item) => {
              if (item.code == ROUTER_ADMIN) {
                if (item.children.length > 0) {
                  item.children.map((adminMenu) => {
                    _this.menuAdmin.push(adminMenu);
                  });
                }
              }
            });
          }
          encryptStorage.setItem("menus", this.menus);
          encryptStorage.setItem("roles", this.roles);
        }
      } else {
        await authService.logout(this.accessToken);
        encryptStorage.removeItem("auth");
        encryptStorage.removeItem("roles");
        encryptStorage.removeItem("menus");
        allStore.logout();
      }
    },
    checkRole(role) {
      if (this.role == "super") return true;
      if (this.roles.includes(role)) return true;
      return false;
    },
    updateSetting(setting) {
      if (encryptStorage.getItem(nameStore)) {
        let userSaveLocalStorage = encryptStorage.getItem(nameStore);
        if (userSaveLocalStorage.setting) {
          let saveSettingLocal = {
            ...userSaveLocalStorage.setting,
          };
          for (const [key, value] of Object.entries(setting)) {
            saveSettingLocal[key] = value;
          }
          userSaveLocalStorage.setting = saveSettingLocal;
          encryptStorage.setItem("auth", JSON.stringify(userSaveLocalStorage));
          this.setting = saveSettingLocal;
          authService.updateSetting(setting);
        }
      } else {
        encryptStorage.setItem("auth", JSON.stringify({ setting: setting }));
      }
    },
    setJsonConfig(value) {
      this.jsonConfig = value;
    },
    async updateConfig(key, value) {
      var json = await authService.updateConfig(key, value);
      if (json.success) {
        this.saveRoute = json.value;
      }
    },
    setUserAfterChange(user) {
      this.name = user.name;
      this.iconId = user.iconId;
      this.icon = user.icon;
      this.setting.isMoneyView = user.setting.isMoneyView;
      if (encryptStorage.getItem(nameStore)) {
        let userSaveLocalStorage = encryptStorage.getItem(nameStore);
        userSaveLocalStorage.name = this.name;
        userSaveLocalStorage.iconId = this.iconId;
        userSaveLocalStorage.menus = this.menus;
        userSaveLocalStorage.setting.isMoneyView = this.setting.isMoneyView;
        userSaveLocalStorage.icon = this.icon;
        encryptStorage.setItem("auth", JSON.stringify(userSaveLocalStorage));
      }
    },
    setAuth(user) {
      this.id = user.id;
      this.name = user.name;
      this.email = user.email;
      this.phone = user.phone;
      this.roles = user.roles;
      this.isLogin = true;
      this.icon = user.icon;
      this.iconId = user.iconId;
      this.permission = user.permission;
      this.accessToken = user.accessToken;
      this.money_total = user.money_total;
      this.money_real = user.money_real;
      this.menus = user.menus;
      this.roles = user.roles;
      if (user.jsonConfig == null) {
        this.jsonConfig = jsonConfigDefault;
      } else {
        this.jsonConfig = user.jsonConfig;
      }

      if (user.setting) {
        this.setting = user.setting;
      } else {
        this.setting = { tabTransction: 1 };
      }
    },
    resetAuth() {
      this.id = "";
      this.name = "";
      this.email = "";
      this.role = "";
      this.isLogin = false;
      this.permission = "";
      this.money_total = 0;
      this.phone = "";
      this.money_real = 0;
      this.jsonConfig = "";
      encryptStorage.removeItem(nameStore);
      encryptStorage.removeItem("auth");
      encryptStorage.removeItem("roles");
      encryptStorage.removeItem("menus");
    },
  },
});
