import LoginPage from "@/pages/LoginPage.vue";
import RegistrationPage from "@/pages/RegisterPage.vue";
import HomePage from "@/pages/HomePage.vue";
import MoneyPage from "@/pages/MoneyPage.vue";
import WalletPage from "@/pages/WalletPage.vue";
import PublicPage from "@/pages/PublicPage.vue";
import ChatPage from "@/pages/ChatPage.vue";
import NoPermissionPage from "@/pages/403Page.vue";
import AccountPage from "@/pages/AccountPage.vue";
import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "./stores/authStore";
import { useToast } from "vue-toastification";
import i18n from "@/langs/i18n";
import {
  ROLE_ADMIN,
  ROUTER_ACCOUNT,
  ROUTER_CHAT,
  ROUTER_CHAT_FRIEND,
  ROUTER_GOLDEN,
  ROUTER_CHAT_INBOX,
  ROUTER_HOME,
  ROUTER_LOGIN,
  ROUTER_MONEY,
  ROUTER_PUBLIC,
  ROUTER_REGISTER,
  ROUTER_WALLET,
  ROUTE_403,
  ROUTE_NO_PERMISSION,
  ROLE_SUPER,
  ROLE_HOME,
  ROLE_TRANSACTION,
  ROLE_WALLETS,
  ROLE_GOLDEN,
  ROLE_LOGIN_REQUIRED,
} from "./useConfig";
import { routesAdmin } from "./routesAdmin";
import ChatUI from "@/components/chat/ChatUI.vue";
import ChatFriend from "@/components/chat/ChatFriend.vue";
import ChatInbox from "@/components/chat/ChatInbox.vue";
import GoldenPage from "./pages/GoldenPage.vue";
import { encryptStorage } from "./utils/encryptLocalStorage";
import authService from "./services/auth.service";
export const routes = [
  {
    path: `/${ROUTER_LOGIN}`,

    name: ROUTER_LOGIN,
    component: LoginPage,
    meta: { titleI18n: "login", preventAccessLogin: true },
  },
  {
    path: `/${ROUTER_REGISTER}`,
    name: ROUTER_REGISTER,
    component: RegistrationPage,
    meta: { titleI18n: "register", preventAccessLogin: true },
  },
  {
    path: `/${ROUTER_ACCOUNT}`,
    name: ROUTER_ACCOUNT,
    component: AccountPage,
    meta: { titleI18n: "SideBarAccount", requiredLogin: true, roles: [ROLE_LOGIN_REQUIRED] },
  },
  {
    path: `/${ROUTER_PUBLIC}/:key`,
    name: ROUTER_PUBLIC,
    component: PublicPage,
    meta: { titleI18n: "publicpage" },
  },
  {
    path: `/`,
    name: "index",
    component: HomePage,
    meta: { titleI18n: "SideBarHome", requiredLogin: true },
    redirect: (to) => {
      const authStore = useAuthStore();

      if (authStore.isLogin) {
        let menus = encryptStorage.getItem("menus");

        if (menus && menus.length > 0) {
          return { name: menus[0].page_url };
        }

        return { name: ROUTER_HOME };
      }
      return { name: ROUTER_LOGIN };
    },
  },

  {
    path: `/home`,
    name: ROUTER_HOME,
    component: HomePage,
    meta: { titleI18n: "SideBarHome", requiredLogin: true, roles: [ROLE_HOME.main] },
    // redirect: (to) => {
    //   const authStore = useAuthStore();
    //   if (authStore.isLogin) {
    //     console.log(33)
    //     return { name: ROUTER_MONEY };
    //   }
    //   return { name: ROUTER_LOGIN };
    // },
  },
  {
    path: `/${ROUTER_MONEY}/:walletId?/:transactionId?`,
    name: ROUTER_MONEY,
    component: MoneyPage,
    meta: {
      titleI18n: "SideBarManagement",
      requiredLogin: true,
      roles: [ROLE_TRANSACTION.money, ROLE_TRANSACTION.list],
    },
  },
  {
    path: `/${ROUTER_WALLET}`,
    name: ROUTER_WALLET,
    component: WalletPage,
    meta: {
      titleI18n: "walletCreateTitle",
      requiredLogin: true,
      roles: [ROLE_WALLETS.main, ROLE_WALLETS.list],
    },
  },
  {
    path: `/${ROUTER_GOLDEN}`,
    name: ROUTER_GOLDEN,
    component: GoldenPage,
    meta: {
      titleI18n: "goldenTitle",
      requiredLogin: true,
      roles: [ROLE_GOLDEN.main, ROLE_GOLDEN.list],
    },
  },
  {
    path: `/${ROUTER_CHAT}`,
    name: ROUTER_CHAT,
    component: ChatUI,
    meta: {
      titleI18n: "chat",
      requiredLogin: true,
    },
    redirect: (to) => {
      return { name: ROUTER_CHAT_INBOX };
    },
    children: [
      {
        path: ROUTER_CHAT_INBOX,
        name: ROUTER_CHAT_INBOX,
        component: ChatInbox,
      },
      {
        path: ROUTER_CHAT_FRIEND,
        name: ROUTER_CHAT_FRIEND,
        component: ChatFriend,
      },
    ],
  },

  //route exception
  {
    path: `/${ROUTE_403}`,

    name: ROUTE_403,
    component: NoPermissionPage,
    meta: { titleI18n: "noPermission" },
  },

  //admin router
  ...routesAdmin,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const DEFAULT_TITLE = "Trang chủ";

router.beforeEach(async (to, from, next) => {
  to.meta.title = to.meta.titleI18n ? i18n.global.t(to.meta.titleI18n) : "";
  const authStore = useAuthStore();

  // authStore.updateConfig('saveRoute',to.name)

  if (to.meta.preventAccessLogin) {
    if (authStore.isLogin) {
      router.push({ name: from.name });
    }
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = DEFAULT_TITLE;
  }
  if (to.meta.requiredLogin || to.meta.admin) {
    if (!authStore.isLogin) {
      if (to.meta.admin) {
        router.push({ name: "login", query: { messageAdminNoRole: true } });
      } else {
        router.push({ name: "login", query: { backURL: to.name, ...router.query } });
      }
    } else {
      if (to.meta.admin) {
        let toRoute = to.path;
        if (toRoute[0] == "/") {
          toRoute = toRoute.substring(1);
          toRoute = toRoute.replace("/", "_");
        }
        let roles = encryptStorage.getItem("roles");
        let auth = encryptStorage.getItem("auth");
        if (auth.role == "super") {
          next();
        } else {
          if (!roles.find((x) => x == toRoute)) {
            router.push({ name: ROUTE_403 });
          }
        }
      } else {
        let roles = encryptStorage.getItem("roles");
        if (
          (to.meta.roles && to.meta.roles.every((element) => roles.includes(element))) ||
          (to.meta.roles && to.meta.roles.includes(ROLE_LOGIN_REQUIRED))
        ) {
          next();
        } else {
          router.push({ name: ROUTE_403 });
        }
      }
    }
  }

  // if(to.meta.admin){
  //   if(authStore.role != ROLE_ADMIN) router.
  // }
  next();
});

export { router };
