<template>
  <vue-progress-bar></vue-progress-bar>
  <router-view v-slot="{ Component }">
    <!-- <v-slide-x-transition> -->
    <component v-if="!loading" :is="Component" />
    <!-- </v-slide-x-transition> -->
  </router-view>
</template>

<script>
import authService from "./services/auth.service";
import allStore from "./stores/allStore";
import { useAuthStore } from "./stores/authStore";
import { useCommonStore } from "./stores/commonStore";
import { useConfigStore } from "./stores/configStore";
import { useWalletStore } from "./stores/walletStore";
import { ROUTE_403, ROUTER_PUBLIC } from "./useConfig";

export default {
  name: "App",
  setup: () => ({
    configStore$: useConfigStore(),
    walletStore$: useWalletStore(),
    authStore$: useAuthStore(),
    commonStore$: useCommonStore(),
  }),
  components: {
    // HomePage
  },

  data: () => ({
    loading: true,
    snackbar: true,
  }),
  created() {},
  mounted() {
    var _this = this;

    _this.loadInitialFunctionStore();
  },

  methods: {
    async loadMe() {
      if (this.$route.name != ROUTER_PUBLIC) {
        let me = await authService.me();
        if (me.user) {
          this.authStore$.roles = me.user.roles;
          await this.authStore$.loadMe(me);
        }
        this.loading = false;
      }
    },
    async loadInitialFunctionStore() {
      try {
        this.configStore$.loadLangAndTheme();
        await this.loadMe();
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
  computed: {},
};
</script>
