import Api from "@/utils/axios";

export default {
  permissions(query = null) {
    return Api.post("/admin/permissions");
  },
  permissionsActive() {
    return Api.post("/admin/permissions",{active:true});
  },
  save(data) {
    return Api.post("/admin/permissions/save",data);
  },
  update(data) {
    return Api.post("/admin/permissions/update",data);
  },
  delete(id) {
    return Api.post("/admin/permissions/delete",{id});
  },
};
