<template lang="">
  <v-row v-if="auth$.checkRole(ROLE_USER_EDIT)" class="justify-center mt-2">
    <v-col cols="12" lg="10">
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-btn
            @click="$router.push({ name: ROUTER_ADMIN_USER })"
            class="float-left ma-0 pa-0"
            color="secondary"
            variant="text"
            icon="mdi mdi-arrow-left"
          ></v-btn>
          <span class="mt-1">{{ $t("adminUserEdit") }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="submit()">
            <v-row class="pa-2">
              <v-col cols="2" class="justify-center">
                <v-dialog max-width="1000" scrollable transition="dialog-bottom-transition">
                  <template v-slot:activator="{ props }">
                    <v-img
                      v-bind="props"
                      @click="isShowModalIcons = !isShowModalIcons"
                      elevation="10"
                      class="hover-pointer hover-shadow ma-auto"
                      style="width: 50px; height: 50px"
                      :src="`${baseUrl}/images/icons/${form.icon}`"
                    ></v-img>
                  </template>
                  <template v-slot:default="{ isActive }">
                    <v-card>
                      <v-toolbar color="white">
                        <v-btn icon color="whiteColor" @click="isActive.value = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("walletCreateTitleIcon") }}</v-toolbar-title>
                      </v-toolbar>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-row>
                          <v-col
                            @click="selectIconWallet(icon, isActive)"
                            v-for="icon in commonStore$.icons"
                            :key="icon.id"
                            cols="3"
                            md="1"
                            lg="1"
                            class="save-icon-image"
                            :class="form.icon == icon.value ? 'save-icon-image-active' : ''"
                          >
                            <img class="hover-pointer" style="width: 50px; height: 50px" :src="`${baseUrl}/images/icons/${icon.value}`" />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- </v-col>
                      </v-row> -->
                  </template>
                </v-dialog>
              </v-col>
              <v-col cols="10" sm="5">
                <v-text-field
                  v-model="v$.form.name.$model"
                  :error-messages="v$.form.name.required.$invalid && v$.form.name.$dirty ? $t('validRequired', { field: $t('signUpName') }) : ''"
                  @input="v$.form.name.$touch()"
                  @blur="v$.form.name.$touch()"
                  name="name"
                  clearable
                  :label="$t('signUpName') + '*'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  name="phone"
                  v-model="v$.form.phone.$model"
                  required
                  clearable
                  :error-messages="validatePhoneError()"
                  @input="v$.form.phone.$touch()"
                  @blur="v$.form.phone.$touch()"
                  :label="$t('signUpPhone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  name="Email"
                  v-model="v$.form.email.$model"
                  required
                  clearable
                  :error-messages="validateEmailError()"
                  @input="v$.form.email.$touch()"
                  @blur="v$.form.email.$touch()"
                  :label="$t('signUpEmail')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  name="password"
                  v-model="form.password"
                  :label="$t('signUpPassword')"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="form.role"
                  :disabled="!auth$.checkRole(ROLE_USER_ROLE)"
                  item-value="id"
                  :item-title="configStore$.lang == LANG_VN ? 'name' : 'name_en'"
                  name="role"
                  @input="v$.form.role.$touch()"
                  @blur="v$.form.role.$touch()"
                  :error-messages="v$.form.role.required.$invalid && v$.form.role.$dirty ? $t('validRequired', { field: $t('adminTdRole') }) : ''"
                  :items="roles"
                  :label="$t('adminTdRole')"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-row class="d-flex justify-center">
                  <v-col cols="12" sm="5" lg="3" md="5" xl="3">
                    <div class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused">
                      <div class="v-input__control">
                        <div class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light" role="textbox">
                          <div class="v-field__field" data-no-activator="">
                            <input
                              :class="
                                parseInt(form.money_total) < 0
                                  ? 'text-info'
                                  : parseInt(form.money_total) > parseInt(form.money_real)
                                  ? 'text-red'
                                  : 'text-info'
                              "
                              name="money_total"
                              size="1"
                              type="text"
                              v-model="form.money_total"
                              class="v-field__input v-field-focused"
                            />
                          </div>
                          <div class="v-field__outline">
                            <div class="v-field__outline__start"></div>
                            <div class="v-field__outline__notch">
                              <label class="v-label v-field-label v-field-label--floating" aria-hidden="true" for="input-36">{{
                                $t("walletCreateInitialMoney") + "*"
                              }}</label>
                            </div>
                            <div class="v-field__outline__end"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="5" lg="3" md="5" xl="3">
                    <div class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused">
                      <div class="v-input__control">
                        <div class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light" role="textbox">
                          <div class="v-field__field" data-no-activator="">
                            <input
                              name="money_total"
                              size="1"
                              type="text"
                              v-model="form.money_real"
                              class="v-field__input v-field-focused"
                              :class="
                                parseInt(form.money_real) < 0
                                  ? 'text-red'
                                  : parseInt(form.money_real) < parseInt(form.money_total)
                                  ? 'text-orange'
                                  : 'text-green'
                              "
                            />
                          </div>
                          <div class="v-field__outline">
                            <div class="v-field__outline__start"></div>
                            <div class="v-field__outline__notch">
                              <label class="v-label v-field-label v-field-label--floating" aria-hidden="true" for="input-36">{{
                                $t("walletCreateRealMoney") + "*"
                              }}</label>
                            </div>
                            <div class="v-field__outline__end"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="form.role" cols="12">
                    <v-select
                      :disabled="!auth$.checkRole(ROLE_USER_ROLE)"
                      :item-title="configStore$.lang == LANG_VN ? 'name' : 'name_en'"
                      v-model="roleSelected"
                      :items="roleSelecting"
                      item-value="id"
                      label="Roles"
                      multiple
                      persistent-hint
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-switch v-model="form.isMoneyView" :label="` ${$t('moneyView')}`"></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" class="float-right" variant="flat" color="cyan"> <v-icon color="white">mdi-pencil</v-icon></v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { useTheme } from "vuetify";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, numeric } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/stores/authStore";
import { useWalletStore } from "@/stores/walletStore";
import { useCommonStore } from "@/stores/commonStore";
import userService from "@/services/admins/user.service";
import { ROUTER_ADMIN_USER } from "@/useConfig";
import _ from "lodash";
import roleService from "@/services/admins/role.service";
export default {
  name: "UserEdit",
  setup: () => ({
    theme$: useTheme(),
    v$: useVuelidate(),
    auth$: useAuthStore(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
    commonStore$: useCommonStore(),
  }),
  data() {
    return {
      isPasswordVisible: false,
      roles: [],
      roleSelected: [],
      roleSelecting: [],
      form: {
        id: "",
        icon: "icon_153.png",
        icon_id: "109",
        name: "",
        role: { label: "Normal", value: "normal" },
        email: "",
        phone: "",
        password: "",
        isMoneyView: true,
        money_total: 0,
        money_real: 0,
      },
    };
  },
  validations() {
    return {
      form: {
        phone: {
          required,
          minLength: minLength(10),
          numeric: numeric,
        },
        email: {
          required,
          email,
        },

        name: {
          required,
        },
        role: {
          required,
        },
      },
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.form.id = this.$route.params.id;
      this.loadRolesActive();
      this.loadById();
    } else {
      this.$router.push({ name: ROUTER_ADMIN_USER });
    }
  },
  methods: {
    async loadRolesActive() {
      const request = await roleService.rolesActive();
      if (request.success) {
        this.roles = request.roles;
      }
    },
    async loadById() {
      this.roleSelecting = [];
      var res = await userService.user(this.$route.params.id);
      if (res.success) {
        this.form.name = res.user.name;
        this.form.email = res.user.email;
        this.form.phone = res.user.phone;
        this.form.money_real = res.user.moneyReal;
        this.form.money_total = res.user.moneyTotal;
        if (res.user.role) {
          const role = this.roles.find((x) => x.id == res.user.role_id);
          if (role) {
            this.form.role = role;
            if (res.user.roles.length > 0) {
              res.user.roles.forEach((item) => {
                if (item.role_id != role.id) {
                  this.roleSelected.push(item.role_id);
                }
              });
            }
          } else {
            this.form.role = "";
          }
        }

        if (res.user.icon) {
          this.form.icon = res.user.icon?.name;
          this.form.icon_id = res.user.icon.id;
        }
        if (res.user.setting) {
          this.form.isMoneyView = res.user.setting.isMoneyView == 0 ? false : true;
        }
      } else {
        this.$router.push({ name: ROUTER_ADMIN_USER });
      }
    },
    async submit() {
      var _this = this;
      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) {
        this.loadingButtonSubmit = false;
        return;
      }
      var body = {
        name: this.form.name,
        id: this.form.id,
        phone: this.form.phone,
        email: this.form.email,
        password: this.form.password,
        isMoneyView: this.form.isMoneyView,
        role: this.form.role.id ? this.form.role.id : this.form.role,
        roles: this.roleSelected,
        iconId: this.form.icon_id,
        moneyTotal: this.removeAllSpecialCharacter(this.form.money_total),
        moneyReal: this.removeAllSpecialCharacter(this.form.money_real),
      };



      var saveRes = await userService.update(body);
      if (saveRes.success) {
        this.$router.push({ name: ROUTER_ADMIN_USER });
        this.toast$.success(this.$t("fieldSuccess", { field: this.$t("SideBarAccount"), action: this.$t("actionUpdated") }));
      } else {
        if (saveRes.errorLists?.phone) {
          let messageAlreadyPhone = this.$t("validAlready", { field: this.$t("signUpPhone") });
          this.toast$.error(messageAlreadyPhone);
        } else if (saveRes.errorLists?.email) {
          let messageAlreadyEmail = this.$t("validAlready", { field: this.$t("signUpEmail") });
          this.toast$.error(messageAlreadyEmail);
        } else {
          this.toast$.error(saveRes.message);
        }
      }
    },
    selectIconWallet(icon, isActive) {
      this.form.icon = icon.value;
      this.form.icon_id = icon.id;
      isActive.value = false;
    },
    validateEmailError() {
      let errorEmailMessage = "";
      if (this.v$.form.email.required.$invalid && this.v$.form.email.$dirty) {
        errorEmailMessage = this.$t("validRequired", { field: this.$t("signUpEmail") });
      }
      if (this.v$.form.email.email.$invalid && this.v$.form.email.$dirty) {
        errorEmailMessage = this.$t("validInvalid", { field: this.$t("signUpEmail") });
      }
      return errorEmailMessage;
    },
    validatePhoneError() {
      let errorMessage = "";
      if (this.v$.form.phone.required.$invalid && this.v$.form.phone.$dirty) {
        errorMessage = this.$t("validRequired", { field: this.$t("signUpPhone") });
      }
      if (this.v$.form.phone.minLength.$invalid && this.v$.form.phone.$dirty) {
        errorMessage = this.$t("validMinLength", { field: this.$t("signUpPhone"), min: 10 });
      }
      if (this.v$.form.phone.numeric.$invalid && this.v$.form.phone.$dirty) {
        errorMessage = this.$t("validInvalid", { field: this.$t("signUpPhone") });
      }

      return errorMessage;
    },
  },
  watch: {
    "form.money_total": function (new_value) {
      this.form.money_total = this.formatCurrency(new_value + "");
    },
    "form.money_real": function (new_value) {
      this.form.money_real = this.formatCurrency(new_value + "");
    },
    "form.role": function (new_value) {
      if (new_value) {
        this.roleSelecting = [];

        this.roles.map((item) => {
          const id = new_value.id ? new_value.id : new_value;
          if (id != item.id) {
            this.roleSelecting.push(item);
          }
        });
      }
    },
  },
};
</script>
<style lang=""></style>
