<template>
  <div class="row">
    <Module v-if="auth$.checkRole(ROLE_MODULE_MAIN)"></Module>
  </div>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import permissionService from "@/services/permission.service";
import Module from "./modules/Module.vue";
import Role from "./modules/Role.vue";
import { useAuthStore } from "@/stores/authStore";
export default {
  name: "AdminModulePage",
  setup: () => ({
    auth$: useAuthStore(),
  }),
  components: {
    MoneyUI,
    Module,
    Role
  },
  data() {
    return {
      flag: false,
      permissions: [],
    };
  },
  mounted() {

  },
  methods: {
   
  },
};
</script>
<style lang=""></style>
