import { TYPE } from "vue-toastification";
// import i18n from "@/langs/i18n";
// import { useTheme } from "vuetify";
export const DEFAULT_LANG = "vn";
export const LANG_VN = "vn";
export const LANG_EN = "en";
export const DEFAULT_THEME = "light";
export const DARK_THEME = "dark";
export const LIGHT_THEME = "light";

export const ROUTER_HOME = "home";
export const ROUTER_MONEY = "money";
export const ROUTER_WALLET = "wallet";
export const ROUTER_GOLDEN = "golden";
export const ROUTER_LOGIN = "login";
export const ROUTER_REGISTER = "register";
export const ROUTER_TRANSACTION = "transaction";
export const ROUTER_PUBLIC = "public";
export const ROUTER_ACCOUNT = "account";

export const ROUTER_CHAT = "chat";
export const ROUTER_CHAT_FRIEND = "friend";
export const ROUTER_CHAT_INBOX = "inbox";

export const ROUTE_403 = "403";

export const ROUTER_ADMIN = "system";

export const ROUTER_ADMIN_USER = "user";
export const ROUTER_ADMIN_USER_ADD = "userAdd";
export const ROUTER_ADMIN_USER_EDIT = "userEdit";

export const ROUTER_ADMIN_ICON = "icon";
export const ROUTER_ADMIN_ICON_ADD = "iconAdd";
export const ROUTER_ADMIN_ICON_EDIT = "iconEdit";

export const ROUTER_ADMIN_DASHBOARD = "adminDashboard";
export const ROUTER_ADMIN_CATEGORY = "category";

export const ROUTER_ADMIN_PERMISSION = "permission";
export const ROUTER_ADMIN_MODULES = "module";
export const ROUTER_ADMIN_ROLE = "role";
export const ROUTER_ADMIN_ROLE_ADD = "roleAdd";
export const ROUTER_ADMIN_ROLE_EDIT = "roleEdit";

export const ROLE_ADMIN = "system";
export const ROLE_SUPER = "super";
export const ROLE_NORMAL = "normal";
export const ROLE_LOGIN_REQUIRED = "only_login";
export const ROLE_HOME = {
  main: "home",
};
export const ROLE_WALLETS = {
  main: "wallet",
  list: "wallet_list",
  add: "wallet_add",
  edit: "wallet_edit",
  delete: "wallet_delete",
};

export const ROLE_GOLDEN = {
  main: "golden",
  price: "golden_prices",
  total: "golden_total",
  profit: "golden_profit",
  list: "golden_list",
  add: "golden_add",
  edit: "golden_edit",
  delete: "golden_delete",
};

export const ROLE_TRANSACTION = {
  money:'money',
  main: "transaction",
  list: "transaction_list",
  add: "transaction_add",
  edit: "transaction_edit",
  delete: "transaction_delete",
  publish: "transaction_publish",
};

export const ROLE_ICON = {
  main: "system_icon",
  list: "system_icon_list",
  add: "system_icon_add",
  edit: "system_icon_edit",
  delete: "system_icon_delete",
};

export const ROLE_USER = {
  main: "system_user",
  add: "system_user_add",
  edit: "system_user_edit",
  delete: "system_user_delete",
  password: "system_user_password",
  role: "system_user_role",

};

export const ROLE_ROLE = {
  main: "system_role",
  list: "system_role_list",
  add: "system_role_add",
  edit: "system_role_edit",
  delete: "system_role_delete",
};

export const ROLE_MODULE = {
  main: "system_module",
  list: "system_module_list",
  add: "system_module_add",
  edit: "system_module_edit",
  delete: "system_module_delete",
  add_feature: "system_module_add_feature",
  edit_feature: "system_module_edit_feature",
  delete_feature: "system_module_delete_feature",

  add_role: "system_module_add_role",
  edit_role: "system_module_edit_role",
  delete_role: "system_module_delete_role",
};

export const optionsVueProgressBar = {
  color: "#9155FD",
  failedColor: "#FF4F57",
  thickness: "3px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

export const optionToast = {
  toastDefaults: {
    closeButtonClassName: "closeButtonClassName",
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
      timeout: 6000,
    },
    [TYPE.SUCCESS]: {
      timeout: 2000,
    },
    [TYPE.INFO]: {
      timeout: 2000,
    },
  },
};

export const dateFormatSaveMoney = (date, time = false) => {
  var d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();
  let hour = d.getHours();
  let minute = d.getMinutes();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.toString().length < 2) hour = "0" + hour;
  if (minute.toString().length < 2) minute = "0" + minute;

  if (time) {
    return `${year}-${month}-${day}T${hour}:${minute}`;
  }
  return `${year}-${month}-${day}`;
};

// export function loadLangAndThemeLocalStorage() {
//   const nameConfig = 'config'
//   let vuetifyTheme = useTheme();
//   if (localStorage.getItem(nameConfig)) {
//     let config = JSON.parse(localStorage.getItem(nameConfig));
//     if(config?.lang){
//       i18n.global.locale = config.lang;
//     }
//     if(config?.theme){
//       vuetifyTheme.global.name.value = config.theme;
//     }
//   }
// }
