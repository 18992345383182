<template>
  <v-row>
    <v-col v-if="authStore.checkRole(ROLE_GOLDEN_PROFIT)" cols="12" lg="12">
      <v-card class="pa-3">
        <v-card-title>
          <v-row>
            <v-col cols="12" lg="12">
              <v-row class="mx-auto">
                <v-col cols="12" :lg="display.lg.value && display.lgAndDown.value && display.lgAndUp.value ? 3 : 2" sm="4" md="4" xl="2">
                  <v-text-field
                    @change="changeDateProfit()"
                    style="width: 200px"
                    :label="$t('MoneyTransactionFrom') + '*'"
                    name="dateCreate"
                    v-model="fromProfit"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" :lg="display.lg.value && display.lgAndDown.value && display.lgAndUp.value ? 3 : 2" sm="4" md="4" xl="2">
                  <v-text-field
                    @change="changeDateProfit()"
                    style="width: 200px"
                    :label="$t('MoneyTransactionTo') + '*'"
                    name="dateCreate"
                    v-model="toProfit"
                    type="date"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-chart style="height: 500px" :option="optionProfit" autoresize />

        <span class="text-body-2 float-right mt-2 font-italic">{{ goldenStore.profitDate }}</span>
      </v-card>
    </v-col>
    <v-col v-if="authStore.checkRole(ROLE_GOLDEN_TOTAL)" cols="12" lg="12">
      <v-card class="pa-3 mt-5">
        <v-card-title>
          <v-row>
            <v-col cols="12" lg="12">
              <v-row class="mx-auto">
                <v-col cols="12" :lg="display.lg.value && display.lgAndDown.value && display.lgAndUp.value ? 3 : 2" sm="4" md="4" xl="2">
                  <v-text-field
                    @change="changeDate()"
                    style="width: 200px"
                    :label="$t('MoneyTransactionFrom') + '*'"
                    name="dateCreate"
                    v-model="from"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" :lg="display.lg.value && display.lgAndDown.value && display.lgAndUp.value ? 3 : 2" sm="4" md="4" xl="2">
                  <v-text-field
                    @change="changeDate()"
                    style="width: 200px"
                    :label="$t('MoneyTransactionTo') + '*'"
                    name="dateCreate"
                    v-model="to"
                    type="date"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-chart style="height: 500px" :option="option" autoresize />
        <span class="text-body-2 float-right mt-2 font-italic">{{ goldenStore.profitDate }}</span>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent, ToolboxComponent, VisualMapComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, provide, reactive, onMounted, watch, watchEffect, defineProps } from "vue";
import { useCommonStore } from "@/stores/commonStore";
import { useAuthStore } from "@/stores/authStore";
import { useConfigStore } from "@/stores/configStore";
import goldenService from "@/services/golden.service";
import i18n from "@/langs/i18n";
import { useDisplay, useLocale, useTheme } from "vuetify";
import { dateFormatSaveMoney, ROLE_GOLDEN } from "@/useConfig";
import { useGoldenStore } from "@/stores/goldenStore";

use([CanvasRenderer, LineChart, TitleComponent, BarChart, TooltipComponent, LegendComponent, GridComponent, ToolboxComponent, VisualMapComponent]);
const authStore = useAuthStore();
const props = defineProps(["temp"]);
const configStore = useConfigStore();
const theme = useTheme();
const goldenStore = useGoldenStore();
const display = useDisplay();
const locale = useLocale();
const fromProfit = ref("");
const from = ref("");
const toProfit = ref("");
const to = ref("");
const fromPrice = ref("");
const loadingSJC = ref(false);
const arraySelectPriceChart = ref([]);
const selectedPriceChart = ref([]);
const toPrice = ref("");
const loading = ref(true);
const goldens = ref({});
const checkObject = reactive({ id: 1, price: 1000 });
const themeConfig = configStore.theme;
const SJC = "sjc";
const PNJ = "pnj";
const listSJCSell = ref();
const listSJCBuy = ref();
const option = ref({
  title: {
    text: "",
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: [i18n.global.t("WalletTableTotal")],
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    axisLabel: {
      marginBottom: 300,

      fontSize: 15,
      formatter: (val) => `${val / 1000000}`,
    },
  },

  series: [
    {
      name: i18n.global.t("WalletTableTotal"),
      type: "line",
      smooth: false,
      data: [],
    },
  ],
});

const optionProfit = ref({
  title: {
    text: "",
  },
  tooltip: {
    trigger: "axis",
    color: "green",
    axisPointer: {
      lineStyle: {
        color: "green",
      },
    },
  },
  legend: {
    data: [
      {
        name: i18n.global.t("GoldenTableProfit"),
        itemStyle: {
          color: "green",
        },
      },
    ],
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],

    axisLine: {
      lineStyle: {
        color: "blue",
        show: true,
        width: 2,
      },
    },
    axisLabel: {
      color: "default",
    },
  },
  yAxis: {
    type: "value",
    axisLabel: {
      marginBottom: 300,
      fontSize: 15,
      formatter: (val) => `${val / 1000000}`,
    },
  },
  visualMap: {
    show: false,
    dimension: 1,
    pieces: [
      {
        lte: 0,
        color: "red",
      },

      {
        gt: 1,
        color: "green",
      },
    ],
  },
  series: [
    {
      name: i18n.global.t("GoldenTableProfit"),
      type: "line",
      smooth: false,
      markArea: {
        itemStyle: {
          color: "red",
        },
      },
      data: [],
    },
  ],
});

const loadGoldenChart = async () => {
  loading.value = true;

  let goldensRequest = await goldenService.goldenCharts(from.value, to.value);
  let arrayDate = [];
  let profit = [];
  let final = [];
  if (goldensRequest.success) {
    goldens.value = goldensRequest.histories;
    goldensRequest.histories.map((item) => {
      arrayDate.push(item.createdAt);
      profit.push(item.profit);
      final.push(item.final);
    });

    option.value.xAxis.data = arrayDate;
    option.value.series[0].data = final;
    option.value.yAxis.max = Math.round(parseInt(final[final.length - 1]) + 100000000);
  }
  loading.value = false;
};

const loadGoldenChartProfit = async () => {
  loading.value = true;

  let goldensRequest = await goldenService.goldenCharts(fromProfit.value, toProfit.value);
  let arrayDate = [];
  let profit = [];
  let final = [];
  if (goldensRequest.success) {
    goldens.value = goldensRequest.histories;
    goldensRequest.histories.map((item) => {
      arrayDate.push(item.createdAt);
      profit.push(item.profit);
      final.push(item.final);
    });

    optionProfit.value.xAxis.data = arrayDate;
    optionProfit.value.series[0].data = profit;
  }
  loading.value = false;
};

onMounted(() => {
  if (!props.temp) {
    provide(THEME_KEY, "light");
    let date = new Date();
    date.setMonth(date.getMonth() - 12);
    from.value = dateFormatSaveMoney(date.toDateString());
    fromPrice.value = dateFormatSaveMoney(date.toDateString());
    to.value = dateFormatSaveMoney(new Date());
    toPrice.value = dateFormatSaveMoney(new Date());
    selectedPriceChart.value = goldenStore.arrayGoldeType;


    let dateProfit = new Date();
    dateProfit.setMonth(dateProfit.getMonth() - 8);
    fromProfit.value = dateFormatSaveMoney(dateProfit.toDateString());
    toProfit.value = dateFormatSaveMoney(new Date());
    loadGoldenChart();
    loadGoldenChartProfit();
  }
});

const changeDate = () => {
  loadGoldenChart();
};

const changeDateProfit = () => {
  loadGoldenChartProfit();
};
</script>

<style scoped>
.chart {
  height: 100vh;
}
</style>
