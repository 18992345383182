<template>
  <v-app>
    <SideBar></SideBar>
    <SideBarAdmin v-if="this.configStore$.isShowSideBarAdmin && !loading"></SideBarAdmin>
    <Navbar></Navbar>
    <v-main v-if="!loading" class="">
      <slot></slot>
    </v-main>
  </v-app>
</template>
<script>
import { useConfigStore } from "@/stores/configStore";
import { useTheme } from "vuetify/lib/framework.mjs";

import SideBar from "./SideBar.vue";
import SideBarAdmin from "./SideBarAdmin.vue";
import Navbar from "./Navbar.vue";
import { useWalletStore } from "@/stores/walletStore";
import { useAuthStore } from "@/stores/authStore";
import { useCommonStore } from "@/stores/commonStore";
import authService from "@/services/auth.service";
import { ROUTER_LOGIN, ROUTER_PUBLIC } from "@/useConfig";
export default {
  name: "MoneyUI",
  setup: () => ({
    configStore$: useConfigStore(),
    theme$: useTheme(),
    walletStore$: useWalletStore(),
    authStore$: useAuthStore(),
    commonStore$: useCommonStore(),
  }),
  components: {
    SideBar,
    Navbar,
    SideBarAdmin,
  },
  data: () => ({
    show: false,
    UILang: "",
    snackbar: true,
    isShowAdminSideBar: false,
    loading: true,
  }),
  mounted() {
    if (this.authStore$.isLogin) {
      this.loadInitialFunctionStore();
      this.displaySideBarWithRouteAdmin();
    }
  },

  methods: {
    displaySideBarWithRouteAdmin() {
      let arrayDisplaySideBar = [];
      if (this.routesAdmin.length > 0) {
        this.routesAdmin.map((item) => {
          arrayDisplaySideBar.push(item.name);
          if (item.children?.length > 0) {
            item.children.map((route) => {
              arrayDisplaySideBar.push(route.name);
              if (route.children?.length > 0) {
                route.children.map((route2) => {
                  arrayDisplaySideBar.push(route2.name);
                });
              }
            });
          }
        });
      }
      if (arrayDisplaySideBar.indexOf(this.$route.name) != -1) {
        this.configStore$.setIsShowSideBarAdmin(true);
      } else {
        this.configStore$.setIsShowSideBarAdmin(false);
      }
    },
    async loadInitialFunctionStore() {
      try {
        if (this.$route.name != ROUTER_PUBLIC || this.$route.name != ROUTER_LOGIN) {
        }
        if (!this.walletStore$.walletSelected?.id) {
          await this.walletStore$.loadWalletsAndUser();
        }
        if (this.commonStore$.icons.length == 0 || this.commonStore$.icons.length == 0) {
          // await this.commonStore$.loadCommonData();
        }
        // var res = await authService.me();
        // if (res.success) {
        //   if (res.auth) {
        //     this.authStore$.setAuth(res.auth);
        //     this.authStore$.setUserAfterChange(res.auth);
        //   }
        // }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
  watch: {
    "$route.name": function (newValue) {
      this.displaySideBarWithRouteAdmin();
    },
  },
};
</script>
