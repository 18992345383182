import Api from "@/utils/axios";

export default {
  wallets(params) {
    return Api.post("/wallets", { ...params });
  },

  getDataCreate() {
    return Api.post("/wallets/wallet-data-create", { action: "wallet-data-create" });
  },
  save(data) {
    return Api.post("/wallets/save", { ...data });
  },
  update(wallet, wallet_id) {
    return Api.post("/wallets/update", { wallet_id: wallet_id, ...wallet });
  },
  convertRemainMoney(wallet_id) {
    return Api.post("/wallets/convertRemainMoney", { wallet_id: wallet_id });
  },
  delete(wallet_id) {
    return Api.post("/wallets/delete", { action: "delete", wallet_id: wallet_id });
  },
  changeStatus(wallet_id, status) {
    return Api.post("/wallets/changeStatus", { wallet_id: wallet_id, status: status });
  },
  updateWalletSelect(wallet_id) {
    return Api.post("/wallets/updateWalletSelect", { wallet_id: wallet_id });
  },
  updateUserBalance(data) {
    return Api.post("/users/update-money", { ...data });
  },
  changePublicWallet(data) {
    return Api.post("/wallets/changePublicWallet", { ...data });
  },
  publicWallet(mail, walletId, filter) {
    return Api.post("/public", { mail: mail, walletId: walletId, ...filter });
  },
};
