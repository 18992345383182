<template lang="">
  <v-dialog v-model="dialog" persistent transition="dialog-top-transition">
    <template v-slot:activator="{ props }">
      <v-btn  v-if="auth$.checkRole(ROLE_TRANSACTION_ADD)"  color="success" v-bind="props" icon="mdi mdi-plus" variant="text"></v-btn>
      <slot></slot>

    </template>
    <v-col cols="12" lg="8" class="ma-auto">
      <v-card>
        <v-form @submit.prevent="submit()">
          <v-card-title class="pa-5 ma-auto">
            <v-icon size="25" @click="resetForm()"> mdi mdi-refresh</v-icon>
            <span v-if="isEditing">{{ $t("MoneyEditTitle") }}</span> <span v-else>{{ $t("MoneyAddTitle") }}</span
            >{{ walletStore$.walletSelected.name }}

            <v-icon class="float-right" size="25" @click="dialog = false"> mdi mdi-close</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" lg="3">
                  <v-dialog v-model="diaLogCategory">
                    <template v-slot:activator="{ props }">
                      <div
                        class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-input--readonly v-text-field v-combobox v-combobox--single hover-pointer"
                      >
                        <div class="v-input__control hover-pointer" v-bind="props">
                          <div
                            class="v-field v-field--active v-field--dirty v-field--prepended v-field--variant-outlined v-theme--light hover-pointer"
                          >
                            <div class="v-field__prepend-inner">
                              <i
                                class="mdi mdi-menu-down mdi v-icon v-theme--light v-icon--size-default"
                                style="margin-bottom: 11px"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div class="v-field__field pa-0 hover-pointer" data-no-activator="">
                              <div class="v-field__input pa-0" data-no-activator="">
                                <div class="v-combobox__selection">
                                  <img
                                    v-if="categorySelected?.icon"
                                    elevation="10"
                                    class="hover-pointer"
                                    :src="`${baseUrl}/images/icons/${categorySelected.icon?.value}`"
                                    style="width: 40px; height: 36px; margin-top: 7px"
                                  />
                                  <span style="margin: auto; font-size: 17px; margin-left: 10px" v-if="configStore$.lang == LANG_VN" class="b-350" :class="categorySelected.isValue ? 'text-green' : 'text-red'">{{
                                      categorySelected.name 
                                    }}</span>
                                    <span style="margin: auto; font-size: 17px; margin-left: 10px" v-else class="b-350" :class="categorySelected.isValue ? 'text-green' : 'text-red'">{{ categorySelected.nameTranslate }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="v-field__outline hover-pointer">
                              <div class="v-field__outline__start hover-pointer"></div>
                              <div class="v-field__outline__notch hover-pointer">
                                <label class="v-label v-field-label v-field-label--floating hover-pointer">{{ $t("MoneyAddCategory") }}</label>
                              </div>
                              <div class="v-field__outline__end hover-pointer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="v-messages__message mt-2" style="color: red">
                        {{
                          v$.formTransaction.categoryName.required.$invalid && v$.formTransaction.categoryName.$dirty
                            ? $t("validRequired", { field: $t("MoneyAddCategory") })
                            : ""
                        }}
                      </div>
                    </template>
                    <v-col cols="12" lg="4" class="ma-auto">
                      <v-card>
                        <v-card-title>
                          <p><v-icon @click="diaLogCategory = false" size="25"> mdi mdi-close</v-icon>{{ $t("MoneyAddCategory") }}</p>
                          <v-col cols="12" lg="8" sm="6" class="ma-auto">
                            <v-text-field
                              v-model="categorySearch"
                              prepend-inner-icon="mdi-magnify"
                              :label="`${$t('categorySearch')}...`"
                              class=""
                            ></v-text-field>
                          </v-col>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" class="ma-auto">
                              <v-tabs v-model="tabCategory" color="red" align-tabs="center">
                                <v-tab :value="1">{{ $t("categoryAll") }}</v-tab>
                                <v-tab :value="2">{{ $t("categoryExpense") }}</v-tab>
                                <v-tab :value="3">{{ $t("categoryIncome") }}</v-tab>
                              </v-tabs>
                              <v-list class="vb-dragger-styler light" height="400" lines="one">
                                <v-list-item
                                  @click="selectCategory(item)"
                                  v-for="item in modalCategories"
                                  :key="item.id"
                                  class="hover-pointer nav-item-money pa-3 save-active-hover border-bottom-list"
                                  :prepend-avatar="`${baseUrl}/images/icons/${item.icon?.value}`"
                                  subtitle=""
                                >
                                  <v-list-item-title class="">
                                    <span v-if="configStore$.lang == LANG_VN" class="b-350" :class="item.isValue ? 'text-green' : 'text-red'">{{
                                      item.name
                                    }}</span>
                                    <span v-else class="b-350" :class="item.isValue ? 'text-green' : 'text-red'">{{ item.nameTranslate }}</span>
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                      </v-card>
                    </v-col>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    name="dateCreate"
                    :error-messages="
                      v$.formTransaction.dateCreate.required.$invalid && v$.formTransaction.dateCreate.$dirty
                        ? $t('validRequired', { field: $t('MoneyAddCreateDate') })
                        : ''
                    "
                    v-model="v$.formTransaction.dateCreate.$model"
                    @input="v$.formTransaction.dateCreate.$touch()"
                    @blur="v$.formTransaction.dateCreate.$touch()"
                    clearable
                    :label="$t('MoneyAddCreateDate') + '*'"
                    type="datetime-local"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <div class="v-row">
                    <v-col cols="6" sm="3">
                      <v-btn
                        @click="amountPositiveAndNegative(false)"
                        size="small"
                        class="mt-3"
                        :color="formTransaction.amount < 0 ? 'red' : 'default'"
                        :variant="formTransaction.amount < 0 ? 'flat' : 'outlined'"
                        ><span style="font-size: 20px">-</span></v-btn
                      >
                      <v-btn
                        @click="amountPositiveAndNegative(true)"
                        size="small"
                        :color="formTransaction.amount > 0 ? 'success' : 'default'"
                        :variant="formTransaction.amount > 0 ? 'flat' : 'outlined'"
                        class="ml-2 mt-3"
                        ><span style="font-size: 15px">+</span></v-btn
                      >
                    </v-col>
                    <v-col cols="6" sm="9">
                      <div
                        class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused hover-border-primary"
                      >
                        <div class="v-input__control">
                          <div class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light" role="textbox">
                            <div class="v-field__field" data-no-activator="">
                              <input
                                :style="formTransaction.amount > 0 ? 'color:green' : formTransaction.amount == 0 ? '' : 'color:red'"
                                name="money_total"
                                size="1"
                                type="text"
                                v-model="amountUI"
                                class="v-field__input v-field-focused"
                              />
                            </div>
                            <div class="v-field__outline">
                              <div class="v-field__outline__start hover-border-primary"></div>
                              <div class="v-field__outline__notch">
                                <label class="v-label v-field-label v-field-label--floating" aria-hidden="true" for="input-36">{{
                                  $t("MoneyAddAmount")
                                }}</label>
                              </div>
                              <div class="v-field__outline__end hover-border-primary"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    name="note"
                    v-model="v$.formTransaction.note.$model"
                    :error-messages="
                      v$.formTransaction.note.required.$invalid && v$.formTransaction.note.$dirty
                        ? $t('validRequired', { field: $t('MoneyAddCreateNote') })
                        : ''
                    "
                    @input="v$.formTransaction.note.$touch()"
                    @blur="v$.formTransaction.note.$touch()"
                    block
                    clearable
                    :label="$t('MoneyAddCreateNote') + '*'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="formTransaction.description"
                    clearable
                    :label="$t('MoneyAddCreateDescription')"
                    :variant="'outlined'"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-file-input
                    v-model="formTransaction.files"
                    placeholder="Upload your documents"
                    :error-messages="errorFile"
                    :label="$t('MoneyAddCreateFile')"
                    multiple
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ fileNames }">
                      <template v-for="fileName in fileNames" :key="fileName">
                        <v-chip size="small" label color="primary" class="me-2">
                          {{ fileName }}
                        </v-chip>
                      </template>
                    </template>
                  </v-file-input>
                  <div class=" mt-1">
                    <v-chip v-for="item in imagesEdit" :key="item.id" class="ma-2 text-wrap">
                      {{ item.name }}
                      <v-icon @click="removeImageEdit(item)" class="ml-1 hover-pointer money-hover-text" size="x-small" icon="mdi mdi-close"></v-icon>
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn variant="text" @click="dialog = false" class="float-left" color="secondary">
              <v-icon>mdi mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :variant="'flat'" type="submit" class="float-right" :color="!isEditing ? 'primary' : 'info'">
              <v-icon v-if="!isEditing">mdi mdi-plus</v-icon>
              <v-icon v-else>mdi mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-dialog>
</template>
<script>
import { useAuthStore } from "@/stores/authStore";
import { useWalletStore } from "@/stores/walletStore";
import { useToast } from "vue-toastification";
import { useTheme } from "vuetify/lib/framework.mjs";
import UISelectCategory from "../layouts/commons/UISelectCategory.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
import moneyService from "@/services/money.service.js";
import { isArray } from "@vue/shared";
import { useMoneyStore } from "@/stores/moneyStore";
import _ from "lodash";
import { useConfigStore } from "@/stores/configStore";
import { DEFAULT_LANG, } from "@/useConfig";
import { TypedChainedSet } from "webpack-chain";
export default {
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    toast$: useToast(),
    v$: useVuelidate(),
    walletStore$: useWalletStore(),
    moneyStore$: useMoneyStore(),
    configStore$: useConfigStore(),
  }),
  props: {  editTransaction: {} },
  name: "MoneyAdd",
  components: {
    UISelectCategory,
  },
  data: () => ({
    dialog: false,
    diaLogCategory: false,
    viewTimeline: true,
    categoryError: "",
    amountUI: 0,
    categorySelected: {},
    isEditing: false,
    categorySelectFirst: {
      id: "",
      name: "",
    },
    errorFile: "",
    tabCategory: 1,
    imagesEdit: [],
    categorySearch: "",
    imagesRemove: [],
    modalCategories: "",
    categoties: [],
    formTransaction: {
      files: [],
      categoryName: "",
      description: "",
      categoryId: "",
      description: "",
      note: "",
      dateCreate: "",
      amount: 0,
    },
  }),
  validations() {
    return {
      formTransaction: {
        dateCreate: {
          required,
        },
        categoryName: {
          required,
        },
        note: {
          required,
        },
        amount: {
          required,
        },
      },
    };
  },
  created() {},
  mounted() {
    this.loadDataCreator() 
    if (!this.isEditing) this.formTransaction.dateCreate = this.dateFormatCurrent(true);

  
  },
  methods: {
    async loadDataCreator() {
      var categoryRequest = await moneyService.categories();
      if (categoryRequest.success) {
        this.categoties = categoryRequest.categories;
        this.modalCategories =  categoryRequest.categories;
      }
    },
    async submit() {
      this.$emit("loadTransactions");
      const isFormCorrect = await this.v$.formTransaction.$validate();
      if (!isFormCorrect) {
        return;
      }

      if (this.errorFile) {
        return;
      }
      var formData = new FormData();

      formData.note = this.formTransaction.note;
      formData.createdDate = this.formTransaction.dateCreate;
      formData.category_id = this.formTransaction.categoryId;
      formData.amount = this.formTransaction.amount;
      formData.description = this.formTransaction.description ? this.formTransaction.description : "";
      formData.images = this.formTransaction.files;
      formData.wallet_id = this.walletStore$.walletSelected.id;
      formData.user_id = this.auth$.id;
      if (!this.isEditing) {
        var saveRequest = await moneyService.save(formData);
        if (saveRequest.success) {
          this.toast$.success(this.$t("fieldSuccess", { field: this.$t("MoneyTransaction"), action: this.$t("actionCreated") }));
          this.dialog = false;
          this.resetForm();
          this.moneyStore$.loadTransaction(this.walletStore$.walletSelected.id, null, this.$route.params.transactionId);
          this.walletStore$.loadWalletsAndUser();
        } else {
          this.toast$.error(this.$t("someThingWrong"));
        }
      } else {
        formData.transaction_id = this.editTransaction.id;
        if (this.imagesRemove.length > 0) {
          var tempImage = [];
          this.imagesRemove.map((img, index) => {
            tempImage.push({ id: img.id, name: img.name });
          });
          formData.removeImages = tempImage;
        }
        var editRequest = await moneyService.update(formData);
        if (editRequest.success) {
          this.toast$.success(this.$t("fieldSuccess", { field: this.$t("MoneyTransaction"), action: this.$t("actionEdited") }));
          this.dialog = false;
          this.resetForm();
          this.moneyStore$.loadTransaction(this.walletStore$.walletSelected.id, null, this.$route.params.transactionId);
          this.walletStore$.loadWalletsAndUser();
        } else {
          this.toast$.error(this.$t("someThingWrong"));
        }
      }
    },
    removeImageEdit(item) {
      var _this = this;
      this.imagesEdit.map((img, index) => {
        if (item.name == img.name) {
          _this.imagesEdit.splice(index, 1);
          _this.imagesRemove.push(img);
        }
      });
    },
    selectCategory(item) {
      this.formTransaction.categoryName = item.name;
      this.formTransaction.categoryId = item.id;
      this.categorySelected = item;
      this.diaLogCategory = false;
      if (this.amountUI == 0) {
        if (this.categorySelected?.isValue == 0) {
          this.amountUI = ""
          this.amountPositiveAndNegative(false);
        } 
      }else if(this.amountUI == '-'){
        this.amountPositiveAndNegative(true);
      }
    },
    resetForm() {
      this.amountUI = 0;
      this.categorySelected = {};

      this.formTransaction = {
        categoryName: "",
        categoryId: "",
        note: "",
        dateCreate: "",
        amount: 0,
      };
      this.imagesEdit = [];
      this.imagesRemove = [];
      this.formTransaction.dateCreate = this.dateFormatCurrent(true);
    },
    errorFileMessage(message) {
      this.errorFile = message;
    },
    amountPositiveAndNegative(expression) {
      if (!expression) {
        this.amountUI = "-" + this.amountUI;
      } else {
        if (this.amountUI[0] == "-") {
          this.amountUI = this.amountUI.toString().slice(1);
        }
      }
    },
  },
  watch: {
    editTransaction: function (item) {
      var _this = this;
      if (!_.isEmpty(item)) {
        this.dialog = true;
        this.isEditing = true;
        var tempSplit = item.createdDate.toString().split(" ");
        var splitDate = tempSplit[1].toString().split("-");
        var editElement = {
          files: [],
          categoryName: item.category.name,
          description: item.description,
          categoryId: item.category.id,
          note: item.note,
          dateCreate: `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}T${tempSplit[0]}`,
          amount: item.amount,
          images: [],
        };
        if (item.images.length > 0) {
          item.images.map((image) => {
            _this.imagesEdit.push(image);
          });
        }
        //format date

        // this.formTransaction.dateCreate = this.dateFormatCurrent(true);
        this.categorySelected = item.category;
        this.amountUI = item.amount;
        this.formTransaction = editElement;
      } else {
        this.isEditing = false;
        this.dialog = false;
      }
    },
    "formTransaction.files": function (new_value) {
      var flag = false;
      if (isArray(new_value)) {
        new_value.map((item) => {
          if (flag == false) {
            let filenameExtension = item.name.split(".").pop();
            if (!this.allowImages.includes(filenameExtension)) {
              flag = true;
            }
          }
        });
        if (flag) {
          this.errorFileMessage(this.$t('validFile')+ JSON.stringify(this.allowImages));
        } else {
          this.errorFileMessage("");
        }
      }
    },
    dialog: function () {
      if (this.dialog == false) {
        this.diaLogCategory = false;
        this.isEditing = false;
        this.resetForm();
        this.$emit("removeProps");
      }
    },
    tabCategory: function (newValue) {
      let tempCategories = this.categoties;
      if (newValue == 1) {
        this.modalCategories = this.categoties;
      } else if (newValue == 2) {
        this.modalCategories = tempCategories.filter((x) => x.isValue === 0);
      } else if (newValue == 3) {
        this.modalCategories = tempCategories.filter((x) => x.isValue == 1);
      }
    },
    categorySearch: function (newValue) {
      let tempCategories = this.categoties;
      let origin = this.categoties;
      if (newValue) {
        if (this.configStore$.lang == this.LANG_VN) {
          this.modalCategories = tempCategories.filter((x) => x.nameSlug.indexOf(newValue) > -1 || x.name.toLowerCase().indexOf(newValue) > -1);
        } else if (this.configStore$.lang == LANG_EN) {
          this.modalCategories = tempCategories.filter(
            (x) => x.nameTranslateSlug.indexOf(newValue) > -1 || x.nameTranslate.toLowerCase().indexOf(newValue) > -1
          );
        }
      } else {
        this.modalCategories = origin;
      }
    },
    "formTransaction.amount": function (new_value) {},
    amountUI: function (new_value) {
      if (this.categorySelected?.isValue == 0) {
      }

      this.amountUI = this.formatCurrency(new_value);
      this.formTransaction.amount = this.removeAllSpecialCharacter(this.amountUI);
    },
    categories: function (newValue) {
      this.modalCategories = newValue;
    },
  },
};
</script>
<style lang=""></style>
