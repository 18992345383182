<template lang="">
  <v-col v-if="auth$.checkRole(ROLE_MODULE_MAIN)" cols="12">
    <v-sheet class="pa-5 mb-3 rounded-xm display-flex" border>
      <v-row>
        <v-col cols="12" lg="3" class="text-center"><span class="text-h5" style="line-height: 48px">Danh sách module và quyền</span></v-col>
        <v-col cols="12" lg="7">
          <v-text-field label="Tìm kiếm..."></v-text-field>
        </v-col>
        <v-col cols="12" lg="2" class="text-center">
          <v-btn
            v-if="auth$.checkRole(ROLE_MODULE_ADD_MODULE)"
            @click="openDialogModule({ type: 'module' })"
            color="success"
            class="display-flex mt-1"
          >
            <v-icon> mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet v-for="item in permissions" class="pa-3 mb-3 rounded-xm" border>
      <v-btn
        color="secondary"
        v-if="item.children.length > 0"
        @click="item.isOpen = !item.isOpen"
        variant="text"
        :icon="item.isOpen ? 'mdi-menu-up' : 'mdi-menu-down'"
        size="sm"
        class="mr-3"
      ></v-btn>
      <!-- <v-icon class="mr-1 hover-pointer">mdi-menu-down</v-icon> -->

      <span @click="item.isOpen = !item.isOpen" class="hover-pointer font-weight-medium"
        ><v-icon class="mr-1">{{ item.icon }}</v-icon
        >{{ configStore$.lang == LANG_VN ? item.name : item.name_en }}</span
      >
      <v-badge class="ml-3" :color="item.active ? 'success' : 'error'" size="small" dot inline></v-badge>
      <v-tooltip v-if="auth$.checkRole(ROLE_MODULE_ADD_FEATURE)" location="top">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            variant="text"
            @click="openDialogModule({ type: 'feature', item })"
            color="green"
            icon="mdi mdi-plus"
            size="sm"
            class="float-right"
          ></v-btn>
        </template>
        <span>Add Feature</span>
      </v-tooltip>
      <v-tooltip v-if="auth$.checkRole(ROLE_MODULE_EDIT_FEATURE)" location="top">
        <template v-slot:activator="{ props }">
          <v-btn
            @click="openDialogModule({ type: 'module', item, edit: true })"
            v-bind="props"
            variant="text"
            color="blue"
            icon="mdi mdi-pencil"
            size="sm"
            class="float-right font-weight-blod"
          ></v-btn>
        </template>
        <span>Edit module</span>
      </v-tooltip>
      <v-tooltip v-if="auth$.checkRole(ROLE_MODULE_DELETE_FEATURE)" location="top">
        <template v-slot:activator="{ props }">
          <v-btn @click="deleteModule(item)" v-bind="props" variant="text" color="red" icon="mdi mdi-delete" size="sm" class="float-right"></v-btn>
        </template>
        <span>Delete module</span>
      </v-tooltip>
      <v-slide-y-transition>
        <v-table class="mt-3" border v-if="item.isOpen && item.children.length > 0" hover block>
          <thead>
            <tr>
              <th class="text-left font-weight-blod">Position</th>
              <th class="text-left font-weight-blod">Name</th>
              <th class="text-left font-weight-blod">Permissons</th>
              <th class="text-left font-weight-blod">Icon</th>
              <th class="text-center font-weight-blod">Active</th>
              <th class="text-left font-weight-blod"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in item.children" :key="item.id">
              <td style="width: 20px" class="text-center">{{ item.menu_index }}</td>
              <td>
                {{ configStore$.lang == LANG_VN ? item.name : item.name_en }}
                <v-badge class="ml-3" :color="item.active ? 'success' : 'error'" size="small" dot inline></v-badge>
              </td>
              <td>
                <v-chip
                  @click="openDialogModule({ type: 'role', item, edit: true })"
                  class="ml-1 hover-pointer"
                  v-if="item.children.length > 0"
                  :color="item.active == 1 ? 'primary' : 'error'"
                  v-for="item in item.children"
                  :key="item.id"
                >
                  {{ configStore$.lang == LANG_VN ? item.name : item.name_en }}
                </v-chip>
              </td>
              <td>
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props"> {{ item.icon }}</v-icon>
                  </template>
                  <span> {{ item.icon }}</span>
                </v-tooltip>
              </td>
              <td style="width: 20px" class="text-left">
                <v-chip class="hover-pointer" color="success" v-if="item.active == 1" variant="outlined"> Active </v-chip>
                <v-chip class="hover-pointer" color="error" v-else variant="outlined"> Inactive </v-chip>
              </td>
              <td>
                <v-tooltip v-if="auth$.checkRole(ROLE_MODULE_ADD_ROLE)" location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      @click="openDialogModule({ type: 'permission', item })"
                      v-bind="props"
                      variant="text"
                      color="green"
                      icon="mdi mdi-plus"
                      size="sm"
                      class="float-right"
                    ></v-btn>
                  </template>
                  <span>Add Permission</span>
                </v-tooltip>
                <v-tooltip v-if="auth$.checkRole(ROLE_MODULE_EDIT_ROLE)" location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      @click="openDialogModule({ type: 'permission', item, edit: true })"
                      v-bind="props"
                      variant="text"
                      color="blue"
                      icon="mdi mdi-pencil"
                      size="sm"
                      class="float-right font-weight-blod"
                    ></v-btn>
                  </template>
                  <span>Edit Permission</span>
                </v-tooltip>
                <v-tooltip v-if="auth$.checkRole(ROLE_MODULE_DELETE_ROLE)" location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      @click="deleteModule(item)"
                      v-bind="props"
                      variant="text"
                      color="red"
                      icon="mdi mdi-delete"
                      size="sm"
                      class="float-right"
                    ></v-btn>
                  </template>
                  <span>Delete Permission</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-slide-y-transition>
    </v-sheet>
    <v-dialog transition="scroll-x-transition" v-model="dialogAdd" width="750">
      <ModuleAdd @eventDiaglogAdd="eventDiaglogAdd($event)" :module="moduleSelected"></ModuleAdd>
    </v-dialog>
  </v-col>
</template>
<script>
import userService from "@/services/admins/user.service";
import { throwStatement } from "@babel/types";
import LoadingAdmin from "../elements/LoadingAdmin.vue";
import permissionService from "@/services/permission.service";
import { useAuthStore } from "@/stores/authStore";
import { useConfigStore } from "@/stores/configStore";
import { useTheme } from "vuetify/lib/framework.mjs";
import { useToast } from "vue-toastification";
import ModuleAdd from "./ModuleAdd.vue";
import { LANG_VN } from "@/useConfig";

export default {
  name: "Module",
  setup: () => ({
    auth$: useAuthStore(),
    toast$: useToast(),
    configStore$: useConfigStore(),
    theme$: useTheme(),
  }),
  components: {
    LoadingAdmin,
    ModuleAdd,
  },
  data() {
    return {
      flag: false,
      permissions: [],
      moduleSelected: "",
      dialogAdd: false,
      moduleSendToAdd: {
        title: "",
        edit: false,
        type: "",
        module: "",
        permissions: "",
      },
    };
  },
  created() {},
  mounted() {
    this.loadPermissions();
    // this.openDialogModule("module");
  },
  methods: {
    async deleteModule(item) {
      this.$swal({
        title: this.$t("swalSureField", { action: this.$t("delete") }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("no"),
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let deleteRequest = await permissionService.delete(item.id);
          if (deleteRequest.success) {
            this.toast$.success(this.$t("fieldSuccess", { field: this.$t("module"), action: this.$t("actionRemove") }));
            this.loadPermissions();
          }
        }
      });
    },
    async loadPermissions() {
      let permissions = await permissionService.permissions();
      if (permissions.success) {
        this.permissions = permissions.permissions;
        this.permissions.map((item) => {
          item.isOpen = true;
        });
      }
    },
    eventDiaglogAdd(value) {
      if (value == "created" || value == "updated") {
        this.toast$.success(
          this.$t("fieldSuccess", { field: this.$t("module"), action: value == "created" ? this.$t("actionCreated") : this.$t("actionUpdated") })
        );
        this.dialogAdd = false;
        this.loadPermissions();
      } else if (value == false || value == true) {
        this.dialogAdd = value;
      }
    },
    openDialogModule(module) {
      if (module.type == "module") {
        this.moduleSendToAdd.type = "module";
        if (module.edit) {
          this.moduleSendToAdd.title = `${module.edit ? this.$t("Edit") : this.$t("Create")} ${this.$t("module")} ${
            this.configStore$.lang && module.edit == LANG_VN ? module.item.name : module.item.name_en
          }`;
        } else {
          this.moduleSendToAdd.title = `${this.$t("Create")} ${this.$t("module")} `;
        }
        this.moduleSendToAdd.module = module.item;
      } else if (module.type == "feature") {
        this.moduleSendToAdd.type = "feature";
        this.moduleSendToAdd.title = `${this.$t("Create")} ${this.$t("moduleFeature")} ${this.$t("of")} ${
          this.configStore$.lang == LANG_VN ? module.item.name : module.item.name_en
        }`;
        this.moduleSendToAdd.module = module.item;
      } else if (module.type == "permission") {
        this.moduleSendToAdd.type = "permission";
        this.moduleSendToAdd.title = `${this.$t("Edit")} ${this.$t("modulePermission")} ${this.$t("of")} ${
          this.configStore$.lang == LANG_VN ? module.item.name : module.item.name_en
        }`;
        this.moduleSendToAdd.module = module.item;
      } else if (module.type == "role") {
        this.moduleSendToAdd.type = "role";
        this.moduleSendToAdd.title = `${this.$t("Edit")} role ${this.$t("of")} ${
          this.configStore$.lang == LANG_VN ? module.item.name : module.item.name_en
        }`;
        this.moduleSendToAdd.module = module.item;
      }
      this.moduleSendToAdd.edit = module.edit ? true : false;
      this.dialogAdd = !this.dialogAdd;
      this.moduleSendToAdd.permissions = this.permissions;
      this.moduleSelected = this.moduleSendToAdd;
    },
  },
  watch: {},
};
</script>
<style lang=""></style>
