<template>
  <MoneyUI>
    <MainWallet v-if="auth$.checkRole(ROLE_WALLET_MAIN)"></MainWallet>
  </MoneyUI>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import MainWallet from "@/components/wallets/MainWallet.vue";
import { useAuthStore } from "@/stores/authStore";
export default {
  name: "WalletPage",
  setup: () => ({
    auth$: useAuthStore(),
  
  }),
  components: {
    MoneyUI,
    MainWallet,
  },
};
</script>
<style lang=""></style>
