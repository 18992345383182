<template>
  <MoneyUI>
    <v-main>
    <v-container :fluid="true" class="mt-1">
      <router-view v-if="$route.name != ROUTER_ADMIN" transition="fade-transition"> </router-view>
      <AdminDashboardPage v-else></AdminDashboardPage>
      <!-- <router-view v-else></router-view> -->
    </v-container>
  </v-main>
  </MoneyUI>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import AdminDashboardPage from "./AdminDashboardPage.vue";
export default {
  name: "AdminUI",
  components: {
    MoneyUI,
    AdminDashboardPage,
  },
};
</script>
<style lang=""></style>
