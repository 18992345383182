<template>
  <MoneyUI>
    <MainGolden  v-if="auth$.checkRole(ROLE_GOLDEN_MAIN)"></MainGolden>
  </MoneyUI>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import MainGolden from "@/components/goldens/MainGolden.vue";
import { useAuthStore } from "@/stores/authStore";
export default {
  name: "GoldenPage",
  setup: () => ({
    auth$: useAuthStore(),
  
  }),
  components: {
    MoneyUI,
    MainGolden,
  },
};
</script>
<style >

</style>
