import Api from "@/utils/axios";

export default {
  icon(filter = null) {
    return Api.post("/admin/icons", { ...filter });
  },
  save(data) {
    return Api.request({
      method: "post",
      url: "/admin/icons/save",
      data: { ...data },
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  update(data) {
    return Api.request({
      method: "post",
      url: "/admin/icons/update",
      data: { ...data },
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  byId(id) {
    return Api.post("/admin/icons/byId", { id: id });
  },
  remove(id) {
    return Api.post("/admin/icons/remove", { id: id });
  },
};
