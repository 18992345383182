<template>
  <MoneyUI >
      <h1>Home Page</h1>
  </MoneyUI>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
export default {
  name: "HomePage",
  components: {
    MoneyUI
  },
  created() {},
  mounted() {},
};
</script>
<style lang=""></style>
