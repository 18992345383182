<template lang="">
  <v-app>
    <v-main>
      <v-container class="mt-20">
        <v-card class="mx-auto my-16 pa-4" max-width="420">
          <Lang :text="true" class="text-right"></Lang>
          <v-card-title class="text-center pb-10 text-uppercase">{{ $t("signUp") }} </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="submit()">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    name="name"
                    v-model="v$.name.$model"
                    required
                    :error-messages="v$.name.required.$invalid && v$.name.$dirty ? $t('validRequired', { field: $t('signUpName') }) : ''"
                    @input="v$.name.$touch()"
                    @blur="v$.name.$touch()"
                    :label="$t('signUpName')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    name="phone"
                    v-model="v$.phone.$model"
                    required
                    :error-messages="validatePhoneError()"
                    @input="v$.phone.$touch()"
                    @blur="v$.phone.$touch()"
                    :label="$t('signUpPhone')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    name="Email"
                    v-model="v$.email.$model"
                    required
                    :error-messages="validateEmailError()"
                    @input="v$.email.$touch()"
                    @blur="v$.email.$touch()"
                    :label="$t('signUpEmail')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    name="password"
                    v-model="v$.password.$model"
                    required
                    :error-messages="v$.password.required.$invalid && v$.password.$dirty ? $t('validRequired', { field: $t('signUpPassword') }) : ''"
                    @input="v$.password.$touch()"
                    @blur="v$.password.$touch()"
                    :label="$t('signUpPassword')"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <VBtn block type="submit">
                    <v-progress-circular :width="2" :size="25" v-if="loadingButtonSubmit" indeterminate color="white"></v-progress-circular
                    ><span v-else>{{ $t("signUp") }}</span>
                  </VBtn>
                </v-col>
                <v-col cols="12" class="text-center text-base">
                  <span>{{ $t("signUpAlreadyAccount") }}</span>
                  <RouterLink class="text-primary" :to="{ name: 'login' }">
                    <span class="ml-2">{{ $t("login") }} </span>
                  </RouterLink>
                </v-col>
                <!-- <v-col cols="12" class="d-flex align-center">
                  <VDivider />
                  <span class="mx-4">{{ $t("or") }}</span>
                  <VDivider />
                </v-col> -->
              </v-row>
            </v-form>
          </v-card-text>

          <!-- <v-card-text>
            <v-row>
              <v-col cols="12 pa-0 text-center">
                <v-btn class="mx-1" depressed outlined color elevation="0" fab icon small>
                  <v-icon class="text-15" x-large color="blue"> mdi-facebook </v-icon>
                </v-btn>
                <v-btn class="mx-1" depressed outlined color elevation="0" fab icon small>
                  <v-icon class="text-15" x-large color="red"> mdi-google </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text> -->
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { useTheme } from "vuetify";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, numeric } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import { useConfigStore } from "@/stores/configStore";
import { encryptStorage } from "@/utils/encryptLocalStorage";
import AuthServices from "@/services/auth.service";
import { useAuthStore } from "@/stores/authStore";
import Lang from "@/components/layouts/Lang.vue";
export default {
  name: "RegistrationPage",
  components: { Lang },
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    v$: useVuelidate(),
    toast$: useToast(),
    configStore$: useConfigStore(),
  }),
  validations() {
    return {
      phone: {
        required,
        minLength: minLength(10),
        numeric: numeric,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      name: {
        required,
      },
    };
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      password: "",
      loadingButtonSubmit: false,
      isPasswordVisible: false,
      errors: {
        alreadyEmail: "",
        alreadyPhone: "",
      },
    };
  },
  mounted() {},
  methods: {
    async submit() {
      try {
        this.errorLogin = "";
        this.loadingButtonSubmit = true;
        this.$Progress.start();
        const isFormCorrect = await this.v$.$validate();
        if (!isFormCorrect) {
          this.$Progress.fail();
          this.loadingButtonSubmit = false;
          return;
        }

        let submitUser = {
          phone: this.phone,
          name: this.name,
          email: this.email,
          password: this.password,
        };
        var request = await AuthServices.register(submitUser);
        if (request.success) {
          let authSave = {
            ...request.auth,
            isLogin: true,
            accessToken: request.accessToken,
          };
          encryptStorage.setItem("auth", JSON.stringify(authSave));
          this.auth$.setAuth(authSave);
          let me = await AuthServices.me();
          this.auth$.loadMe(me);
          this.$router.push({ name: "money" });
          this.$Progress.finish();
        } else {
          this.$Progress.fail();
          if (request.errorLists?.phone) {
            let messageAlreadyPhone = this.$t("validAlready", { field: this.$t("signUpPhone") });
            this.toast$.error(messageAlreadyPhone);
          } else if (request.errorLists?.email) {
            let messageAlreadyEmail = this.$t("validAlready", { field: this.$t("signUpEmail") });
            this.toast$.error(messageAlreadyEmail);
          }
        }
        this.loadingButtonSubmit = false;
      } catch (e) {
        console.log(e);
        this.$Progress.fail();
        this.loadingButtonSubmit = false;
      }
    },
    validateEmailError() {
      let errorEmailMessage = "";
      if (this.v$.email.required.$invalid && this.v$.email.$dirty) {
        errorEmailMessage = this.$t("validRequired", { field: this.$t("signUpEmail") });
      }
      if (this.v$.email.email.$invalid && this.v$.email.$dirty) {
        errorEmailMessage = this.$t("validInvalid", { field: this.$t("signUpEmail") });
      }
      return errorEmailMessage;
    },
    validatePhoneError() {
      let errorMessage = "";
      if (this.v$.phone.required.$invalid && this.v$.phone.$dirty) {
        errorMessage = this.$t("validRequired", { field: this.$t("signUpPhone") });
      }
      if (this.v$.phone.minLength.$invalid && this.v$.phone.$dirty) {
        errorMessage = this.$t("validMinLength", { field: this.$t("signUpPhone") });
      }
      if (this.v$.phone.numeric.$invalid && this.v$.phone.$dirty) {
        errorMessage = this.$t("validInvalid", { field: this.$t("signUpPhone") });
      }

      return errorMessage;
    },
  },
  watch: {},
};
</script>
<style>
.v-text-field .v-input__details {
  padding: 0 !important;
}
</style>
