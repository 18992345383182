<template lang="">
  <v-row class="ma-0">
    <v-col class="pa-0" cols="12" lg="3" sm="5">
      <v-card>
        <v-list color="primary" class="v-list-chatinbox" lines="two">
          <v-list-item v-for="(item, i) in items" :key="i" :value="item.title" color="primary" :prependAvatar="item.prependAvatar">
            <template v-slot:title>
              <div >{{item.title}}</div>
            </template>
            <template v-slot:append>
          <v-btn
            color="grey-lighten-1"
            icon="mdi-dots-horizontal"
            variant="text"
          ></v-btn>
        </template>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "pinia";
import { useTheme, useDisplay } from "vuetify";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toastification";
import { useConfigStore } from "@/stores/configStore";
export default {
  name: "ChatFriend",
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    toast$: useToast(),
    display$: useDisplay(),
  }),
  components: {},
  data: () => ({
    scrollInvoked: 0,
    items: [
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="text-primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Summer BBQ",
        subtitle: `<span class="text-primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle: '<span class="text-primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
      },
     
    ],
  }),
  created() {},
  mounted() {
    console.log(this.display$.smAndUp.value);
  },
  methods: {
    onScroll() {
      this.scrollInvoked++;
    },
  },
  watch: {},
  computed: {},
};
</script>
<style>

</style>
