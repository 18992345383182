<template>
  <UserList v-if="$route.name == ROUTER_ADMIN_USER  && auth$.checkRole(ROLE_USER_MAIN) && auth$.checkRole(ROLE_ICON_MAIN)"></UserList>
  <RouterView v-else></RouterView>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import UserList from "./user/UserList.vue";
import { useAuthStore } from "@/stores/authStore";
import { ROLE_USER } from "@/useConfig";
export default {
  name: "AdminUserPage",
  setup: () => ({
    auth$: useAuthStore(),
  }),
  components: {
    MoneyUI,
    UserList,
  },
};
</script>
<style lang=""></style>
