<template lang="">
  <v-row class="justify-center mt-2">
    <v-col cols="12" lg="10">
      <v-card>
        <v-card-title class="">
          <span>Edit Roles</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="submit()">
            <v-row class="pa-2">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="v$.form.name.$model"
                  :error-messages="v$.form.name.required.$invalid && v$.form.name.$dirty ? $t('validRequired', { field: $t('signUpName') }) : ''"
                  @input="v$.form.name.$touch()"
                  @blur="v$.form.name.$touch()"
                  name="name"
                  clearable
                  :label="$t('signUpName') + '*'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="v$.form.name_en.$model"
                  :error-messages="v$.form.name_en.required.$invalid && v$.form.name_en.$dirty ? $t('validRequired', { field: 'Name EN' }) : ''"
                  @input="v$.form.name_en.$touch()"
                  @blur="v$.form.name_en.$touch()"
                  name="name_en"
                  clearable
                  :label="'Name EN'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="v$.form.code.$model"
                  :error-messages="v$.form.name_en.required.$invalid && v$.form.code.$dirty ? $t('validRequired', { field: 'Code' }) : ''"
                  @input="v$.form.code.$touch()"
                  @blur="v$.form.code.$touch()"
                  name="code"
                  clearable
                  :label="'Code'"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="3">
                <v-switch class="ma-auto" v-model="form.isActive" :label="`Active`"></v-switch>
              </v-col>
              <v-col cols="4" sm="3">
                <v-switch class="ma-auto" color="cyan" v-model="form.default" :label="`Default`"></v-switch>
              </v-col>
              <v-col cols="12">
                <p v-if="isRequiredModules" class="text-error">{{ $t("validRequired", { field: "Modules" }) }}</p>
                <v-treeview
                  class="tree-bg-color"
                  v-model:selected="moduleSelected"
                  :items="modules"
                  collapse-icon="mdi-chevron-down"
                  density="compact"
                  expand-icon="mdi-chevron-right"
                  select-strategy="leaf"
                  color="primary"
                  open-all
                >
                  <template v-slot:title="{ item }">
                    <v-checkbox
                      @click="onClickCheckBox(item, item.isChecked)"
                      :class="item.children ? '' : 'ml-7'"
                      v-model="item.isChecked"
                      color="primary"
                      :label="configStore$.lang == LANG_VN ? item.name : item.name_en"
                      :value="true"
                    ></v-checkbox>
                  </template>
                </v-treeview>
              </v-col>

              <v-col cols="12">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" variant="flat" class="float-right" color="primary"> <v-icon>mdi-pencil</v-icon></v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import userService from "@/services/admins/user.service";
import { throwStatement } from "@babel/types";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, numeric } from "@vuelidate/validators";
import LoadingAdmin from "../elements/LoadingAdmin.vue";
import permissionService from "@/services/permission.service";
import { useAuthStore } from "@/stores/authStore";
import { useConfigStore } from "@/stores/configStore";
import { useTheme } from "vuetify/lib/framework.mjs";
import { useToast } from "vue-toastification";
import { LANG_VN, ROUTER_ADMIN_ROLE } from "@/useConfig";
import roleService from "@/services/admins/role.service";

export default {
  name: "RoleEdit",
  setup: () => ({
    auth$: useAuthStore(),
    toast$: useToast(),
    v$: useVuelidate(),
    configStore$: useConfigStore(),
    theme$: useTheme(),
  }),
  components: {
    LoadingAdmin,
  },
  data() {
    return {
      flag: false,
      role: "",
      permissions: [],
      moduleSelected: "",
      dialogAdd: false,
      moduleSelected: [],
      modules: [],
      isRequiredModules: false,
      form: {
        name: "",
        name_en: "",
        code: "",
        default: false,
        isActive: false,
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        name_en: {
          required,
        },
        code: {
          required,
        },
      },
    };
  },
  created() {},
  async mounted() {
    if (!this.$route.params.id) {
      this.$router.push({ name: ROUTER_ADMIN_ROLE });
    } else {
      await this.loadRoleById();
    }
  },
  methods: {
    async loadRoleById() {
      let requestRole = await roleService.byId({ id: this.$route.params.id });
      if (requestRole.success) {
        this.role = requestRole.role;
        this.form.name = this.role.name;
        this.form.name_en = this.role.name_en;
        this.form.default = this.role.default == 1 ? true : false;
        this.form.code = this.role.code;
        this.form.isActive = this.role.active == 1 ? true : false;
        await this.loadPermissions();
      }
    },
    async loadPermissions() {
      let _this = this;
      let permissions = await permissionService.permissionsActive();
      if (permissions.success) {
        this.permissions = permissions.permissions;
        this.modules = this.transformMenuData(this.permissions);
      }
    },
    async submit() {
      var _this = this;

      const isFormCorrect = await this.v$.form.$validate();
      // if (this.getCheckedItems(this.modules).length == 0) {
      //   this.isRequiredModules = true;
      //   return;
      // } else {
      //   this.isRequiredModules = false;
      // }
      if (!isFormCorrect) {
        return;
      }

      let payload = {
        ...this.form,
        id: this.$route.params.id,
        modules: this.getCheckedItems(this.modules),
      };
      const request = await roleService.update(payload);
      if (request.success) {
        this.$router.push({ name: ROUTER_ADMIN_ROLE });
      }
    },
    transformMenuData(inputData) {
      return inputData.map((item) => {
        if (this.role.modules && this.role.modules.length > 0) {
          let checkExistingRole = this.role.modules.find((x) => x.module_id == item.id);
          let transformedItem = {
            value: item.id,
            title: item.name,
            name: item.name,
            name_en: item.name_en,
            code: item.code,
            isChecked: checkExistingRole ? true : false,
          };
          if (item.children && item.children.length > 0) {
            transformedItem.children = this.transformMenuData(item.children);
          }
          return transformedItem;
        }
      });
    },
    onClickCheckBox(checkboxValue, isChecked) {
      this.checkBoxOnChange(checkboxValue, isChecked);
      if (this.modules.length === 0) {
        this.isRequiredModules = true;
      } else {
        this.isRequiredModules = false;
      }
    },
    checkBoxOnChange(data, isChecked) {
      if (data.children) {
        data.children.map((item) => {
          item.isChecked = !isChecked;
          if (item.children) {
            item.children.map((jtem) => {
              jtem.isChecked = !isChecked;
            });
          }
        });
      }
    },
    getCheckedItems(data) {
      let result = [];

      // Helper function to recursively check items and their children
      function checkItem(item) {
        if (item.isChecked) {
          result.push(item); // Add item to result if it is checked
        }

        // If item has children, recursively check them
        if (item.children && item.children.length > 0) {
          item.children.forEach((child) => checkItem(child));
        }
      }

      // Iterate over all top-level items
      data.forEach((item) => checkItem(item));

      return result;
    },
  },
  watch: {
    modules: function (new_value, old_value) {
      let _this = this;
    },
  },
};
</script>
<style lang=""></style>
