<template lang="">
  <v-navigation-drawer :elevation="$route.name == ROUTER_CHAT_INBOX || $route.name == ROUTER_CHAT_FRIEND ? 0 : 10" expand-on-hover rail v-model="configStore$.drawerState">
    <v-list class="pa-1">
      <v-list-item class="hover-pointer" :prepend-avatar="`${baseUrl}/images/icons/${auth$.icon?.value ? auth$.icon.value : defaultIcon}`" :title="auth$.name" :subtitle="auth$.email"></v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-list density="compact" nav>
      <v-list-item
        v-for="(item, index) in auth$.menus"
        :key="index"
        link
        :to="{ name: item.page_url }"
        :prepend-icon="item.icon"
        :color="item.page_url == ROUTER_ADMIN ? 'saveRed' : 'primary'"
        :value="item.page_url"
        :title="configStore$.lang == LANG_VN ? item.name : item.name_en"
      >
      </v-list-item>
     
    </v-list>
    <template v-slot:append>
      <v-list density="compact" nav>
        <v-list-item @click="changeLang()" class="custom-icon-logout ml-1 d-flex d-sm-none" :title="configStore$.lang == 'en' ? 'VN ' : 'EN '" color="primary" value="ChangLang"></v-list-item>
        <v-list-item
          @click="changeTheme()"
          class="custom-icon-logout d-flex d-sm-none"
          :prepend-icon="configStore$.theme == 'light' ? 'mdi-weather-night' : 'mdi-weather-sunny'"
          :title="configStore$.theme == 'light' ? $t('SideBarChangeThemeLight') : $t('SideBarChangeThemeDark')"
          color="primary"
          value="ChangeTheme"
        ></v-list-item>
        <v-list-item
        link
        :to="{ name: ROUTER_ACCOUNT }"
        prepend-icon="mdi-account"
        :value="ROUTER_ACCOUNT"
        color="primary"
        tool
        :title="$t('SideBarAccount')"
      ></v-list-item>
        <v-list-item @click="logout()" class="custom-icon-logout" prepend-icon="mdi-export" :title="$t('SideBarLogOut')" color="primary" value="Logout"></v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>
<script>
import authService from "@/services/auth.service";
import { useAuthStore } from "@/stores/authStore";
import { useConfigStore } from "@/stores/configStore";
import { encryptStorage } from "@/utils/encryptLocalStorage";
import { useToast } from "vue-toastification";
import { DEFAULT_LANG, ROUTER_ACCOUNT, LANG_VN, Lang_EN } from "@/useConfig";
import { useTheme } from "vuetify/lib/framework.mjs";
import allStore from "@/stores/allStore";

export default {
  setup: () => ({
    auth$: useAuthStore(),
    toast$: useToast(),
    configStore$: useConfigStore(),
    theme$: useTheme(),
    walletStore$: useTheme(),
  }),
  name: "SideBar",
  components: {},
  data: () => ({
    drawerState: true,
  }),
  mounted() {},
  methods: {
    changeTheme() {
      if (this.configStore$.theme == "light") {
        this.configStore$.setTheme("dark");
      } else {
        this.configStore$.setTheme("light");
      }
      this.theme$.global.name.value = this.configStore$.theme;
    },
    changeLang() {
      if (this.configStore$.lang == DEFAULT_LANG) {
        this.configStore$.setLang("en");
      } else {
        this.configStore$.setLang(DEFAULT_LANG);
      }
    },
    async logout() {
      try {
        await authService.logout(this.auth$.accessToken);
        encryptStorage.removeItem("auth");
        allStore.logout();
        this.toast$.info(this.$t("logout"));
        this.$router.push({ name: "login" });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style>
#no-background-hover::before {
  background-color: transparent !important;
}
.custom-icon-logout i {
  transform: rotate(180deg);
}
.save-avatar {
  margin: 7px 7px 7px 8px;
}
</style>
