import Api from "@/utils/axios";

export default {
  goldens() {
    return Api.post("/goldens", {});
  },

  prices(data) {
    return Api.post("/goldens/prices", { action: "prices", ...data });
  },
  goldenCharts(from, to) {
    return Api.post("/goldens/goldenCharts", { from: from, to: to });
  },

  save(data) {
    return Api.post("/goldens/save", { ...data });
  },
  crawlSJCData() {
    return Api.post("/goldens/crawlSJCData", { action: "crawl-SJC-prices" });
  },
  crawlPNJData() {
    return Api.post("/goldens/crawlPNJData", { action: "crawl-PNJ-prices" });
  },
  profitHistories() {
    return Api.post("/goldens/profitHistories", { action: "profit-histories" });
  },
  delete(id) {
    return Api.post("/goldens/delete", { action: "delete", golden_id: id });
  },
  update(data) {
    return Api.post("/goldens/update", { action: "update", ...data });
  },
  updateGoldenCurrentProces() {
    return Api.post("/goldens/updateGoldenCurrentProces", { action: "update-golden-current-prices" });
  },
  updateField(id, field, value) {
    return Api.post("/goldens/updateField", { action: "update-field", id: id, field: field, value: value });
  },
  updateAll(field, value) {
    return Api.post("/goldens/updateAll", { field, value });
  },
};
