<template lang="">
  <v-row v-if="auth$.checkRole(ROLE_ROLE_MAIN)" class="justify-center mt-2">
    <v-col cols="12" lg="10">
      <v-card>
        <v-card-title class="">
          <span>Roles</span>
          <v-btn v-if="auth$.checkRole(ROLE_ROLE_ADD)"  @click="$router.push({ name: ROUTER_ADMIN_ROLE_ADD })" class="float-right" color="green" variant="text" icon="mdi mdi-plus"></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-table hover block>
            <thead>
              <tr>
                <th class="text-left">Roles</th>
                <th class="text-left">Name</th>
                <th class="text-left">Name EN</th>
                <th class="text-left">Code</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in roles">
                <td class="text-left">
                  {{ item.id }}

                  <v-badge size="" v-if="item.code != 'super'" :content="item.count_users">
                    <v-icon icon="mdi-account" size="large"></v-icon>
                  </v-badge>

                  <v-badge class="ml-3" v-if="item.code != 'super'" :color="item.active ? 'success' : 'error'" :content="item.modules.length">
                    <v-icon icon="mdi-hand-back-left" size="large"></v-icon>
                  </v-badge>
                </td>
                <td class="text-left">
                  {{ item.name }}
                  <v-icon v-if="item.code == 'super'" color="blue">mdi mdi-crown-outline</v-icon>
                  <v-icon v-if="item.default" color="green">mdi mdi-check</v-icon>
                </td>
                <td class="text-left">{{ item.name_en }}</td>
                <td class="text-left">{{ item.code }}</td>
                <td class="text-left">
                  <v-btn  @click="onDelete(item)" v-if="item.code != 'super' && auth$.checkRole(ROLE_ROLE_DELETE)" class="ma-1" color="error" icon="mdi-delete" variant="outlined" size="small"></v-btn>
                  <v-btn
                    v-if="item.code != 'super' && auth$.checkRole(ROLE_ROLE_EDIT)"
                    @click="$router.push({ name: ROUTER_ADMIN_ROLE_EDIT, params: { id: item.id } })"
                    :variant="'outlined'"
                    color="info"
                    icon="mdi-pencil"
                    class="ma-1"
                    size="small"
                  ></v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import userService from "@/services/admins/user.service";
import { throwStatement } from "@babel/types";
import LoadingAdmin from "../elements/LoadingAdmin.vue";
import permissionService from "@/services/permission.service";
import { useAuthStore } from "@/stores/authStore";
import { useConfigStore } from "@/stores/configStore";
import { useTheme } from "vuetify/lib/framework.mjs";
import { useToast } from "vue-toastification";
import { LANG_VN } from "@/useConfig";
import roleService from "@/services/admins/role.service";

export default {
  name: "Role",
  setup: () => ({
    auth$: useAuthStore(),
    toast$: useToast(),
    configStore$: useConfigStore(),
    theme$: useTheme(),
  }),
  components: {
    LoadingAdmin,
  },
  data() {
    return {
      flag: false,
      roles: [],
      moduleSelected: "",
      dialogAdd: false,
      moduleSendToAdd: {
        title: "",
        edit: false,
        type: "",
        module: "",
        permissions: "",
      },
    };
  },
  created() {},
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let roles = await roleService.roles();

      if (roles.success) {
        this.roles = roles.roles;
        if (this.roles.length > 0) {
        }
      }
    },
    async onDelete(item) {
      this.$swal({
        title: this.$t("swalSureField", { action: this.$t("delete") }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("no"),
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = await roleService.delete(item.id);
          if (request.success) {
            this.toast$.success(this.$t("fieldSuccess", { field: "Role", action: this.$t("actionRemove") }));
            this.load();
          } else {
            this.toast$.error(request.message);
          }
        }
      });
    },
  },
  watch: {},
};
</script>
<style lang=""></style>
