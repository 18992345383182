<template lang="">
  <v-card class="ma-0 pa-3">
    <h1>
      10 Con mèo hahaha aaa 
    </h1>
  </v-card>
</template>
<script>
import { mapState } from "pinia";
import { useTheme, useDisplay } from "vuetify";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toastification";
import { useConfigStore } from "@/stores/configStore";
export default {
  name: "ChatMessage",
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    toast$: useToast(),
    display$: useDisplay(),
  }),
  components: {},
  data: () => ({
    scrollInvoked: 0,
   
  }),
  created() {},
  mounted() {

  },
  methods: {
    onScroll() {
      this.scrollInvoked++;
    },
  },
  watch: {},
  computed: {},
};
</script>
<style>

</style>
