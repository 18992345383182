<template lang="">
  <v-dialog scrollable v-model="isShow" persistent transition="dialog-top-transition">
    <v-form @submit.prevent="submit()">
      <v-card>
        <v-card-title class="pa-3">
          <span>{{ $t("change") + " " + $t("balanceAccount") }}</span>
          <v-icon class="float-right" size="25" @click="isShow = false"> mdi mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 70vh" class="light">
          <v-row>
            <v-col cols="12" lg="12" class="ma-auto text-center">
              <ElementTotalMoney
                :styleText="`text-h5`"
                :moneyTotal="parseInt(removeAllSpecialCharacter(form.money_total))"
                :moneyReal="parseInt(removeAllSpecialCharacter(form.money_real))"
              ></ElementTotalMoney>
            </v-col>

            <v-col cols="12">
              <v-row class="d-flex justify-center">
                <v-col cols="12" sm="5" lg="3" md="5" xl="3">
                  <div class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused">
                    <div class="v-input__control">
                      <div class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light" role="textbox">
                        <div class="v-field__field" data-no-activator="">
                          <input
                            :class="
                              parseInt(form.money_total) < 0
                                ? 'text-info'
                                : parseInt(form.money_total) > parseInt(form.money_real)
                                ? 'text-red'
                                : 'text-info'
                            "
                            name="money_total"
                            size="1"
                            type="text"
                            v-model="form.money_total"
                            class="v-field__input v-field-focused"
                          />
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <v-btn
                                v-bind="props"
                                @click="form.money_total = form.money_real"
                                :color="
                                  parseInt(form.money_total) < 0
                                    ? 'cyan'
                                    : parseInt(form.money_total) > parseInt(form.money_real)
                                    ? 'saveRed'
                                    : 'cyan'
                                "
                                icon="mdi mdi-sync"
                                variant="text"
                                class="mr-1"
                              ></v-btn>
                            </template>
                            {{ $t("logButtonApplyTotal", { field: $t("WalletTableReal") }) }}
                          </v-tooltip>
                        </div>
                        <div class="v-field__outline">
                          <div class="v-field__outline__start"></div>
                          <div class="v-field__outline__notch">
                            <label class="v-label v-field-label v-field-label--floating" aria-hidden="true" for="input-36">{{
                              $t("walletCreateInitialMoney") + "*"
                            }}</label>
                          </div>
                          <div class="v-field__outline__end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="5" lg="3" md="5" xl="3">
                  <div class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field v-field-focused">
                    <div class="v-input__control">
                      <div class="v-field v-field--active v-field--dirty v-field--variant-outlined v-theme--light" role="textbox">
                        <div class="v-field__field" data-no-activator="">
                          <input
                            name="money_total"
                            size="1"
                            type="text"
                            v-model="form.money_real"
                            class="v-field__input v-field-focused"
                            :class="
                              parseInt(form.money_real) < 0
                                ? 'text-red'
                                : parseInt(form.money_real) < parseInt(form.money_total)
                                ? 'text-orange'
                                : 'text-green'
                            "
                          />
                          <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                              <v-btn
                                v-bind="props"
                                @click="form.money_real = form.money_total"
                                :color="
                                  parseInt(form.money_real) < 0 ? 'cyan' : parseInt(form.money_real) < parseInt(form.money_total) ? 'saveRed' : 'cyan'
                                "
                                icon="mdi mdi-sync"
                                variant="text"
                                class="mr-1"
                              ></v-btn>
                            </template>
                            {{ $t("logButtonApplyTotal", { field: $t("WalletTableTotal") }) }}
                          </v-tooltip>
                        </div>
                        <div class="v-field__outline">
                          <div class="v-field__outline__start"></div>
                          <div class="v-field__outline__notch">
                            <label class="v-label v-field-label v-field-label--floating" aria-hidden="true" for="input-36">{{
                              $t("walletCreateRealMoney") + "*"
                            }}</label>
                          </div>
                          <div class="v-field__outline__end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="from" @change="filterTransaction('from')" :label="$t('MoneyTransactionFrom')" type="date"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="to" @change="filterTransaction('to')" :label="$t('MoneyTransactionTo')" type="date"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="query"
                    @change="filterTransaction('query')"
                    :label="$t('MoneyTransactionSearch') + '...'"
                    type="text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-chip
                    color="green"
                    class="hover-pointer mr-1 ml-1"
                    @click="filterTransaction('inflow')"
                    :class="configStore$.filter.log.state == 'inflow' ? 'rounded-shaped border-primary-solid' : ''"
                    >{{ $t("MoneyTransactionInflow") }}</v-chip
                  >
                  <v-chip
                    color="red"
                    class="hover-pointer mr-1 ml-1"
                    @click="filterTransaction('outflow')"
                    :class="configStore$.filter.log.state == 'outflow' ? 'rounded-shaped border-primary-solid' : ''"
                    >{{ $t("MoneyTransactionOutflow") }}</v-chip
                  >
                  <v-chip
                    color="info"
                    class="hover-pointer mr-1 ml-1"
                    @click="filterTransaction('balance')"
                    :class="configStore$.filter.log.state == 'balance' ? 'rounded-shaped border-primary-solid' : ''"
                    >{{ $t("balance") }}</v-chip
                  >
                </v-col>
              </v-row>
              <v-table v-if="!loading" hover fixed-header>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("MoneyAddCreateNote") }}</th>
                    <th class="text-left">{{ $t("MoneyAddCreateState") }}</th>
                    <th class="text-left">{{ $t("WalletTableTotal") }}</th>
                    <th class="text-left">{{ $t("WalletTableReal") }}</th>
                    <th class="text-left">{{ $t("adminTdCreated") }}</th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in walletStore$.logs.data" :key="item.id">
                    <td>{{ item.note }}</td>
                    <td>
                      <v-icon v-if="item.state?.toString().search('balance') != -1" color="orange">mdi mdi-star</v-icon>
                      <v-icon v-if="item.state?.toString().search('delete') != -1" color="red">mdi mdi-close</v-icon>
                      <v-icon v-if="item.state?.toString().search('create') != -1" color="green">mdi mdi-plus</v-icon>
                      <v-icon v-if="item.state?.toString().search('update') != -1" color="info">mdi mdi-update</v-icon>
                    </td>
                    <td>
                      <span>{{ formatNumber(item.fromValueTotal) }}</span>

                      <span class="ml-2 mr-2">/</span>

                      <span
                        :class="
                          parseInt(item.toValueTotal) > parseInt(item.fromValueTotal)
                            ? 'text-green'
                            : item.fromValueTotal == item.toValueTotal
                            ? 'text-info'
                            : 'text-red'
                        "
                        >{{ formatNumber(item.toValueTotal) }}
                        <v-icon
                          size="17"
                          class="d-none d-md-inline"
                          :class="
                            parseInt(item.toValueTotal) > parseInt(item.fromValueTotal)
                              ? 'text-green'
                              : parseInt(item.fromValueTotal) == parseInt(item.toValueTotal)
                              ? 'text-info'
                              : 'text-red'
                          "
                          >{{
                            parseInt(item.toValueTotal) > parseInt(item.fromValueTotal)
                              ? "mdi mdi-arrow-up"
                              : parseInt(item.fromValueTotal) == parseInt(item.toValueTotal)
                              ? ""
                              : "mdi mdi-arrow-down"
                          }}
                        </v-icon>
                      </span>
                    </td>
                    <td>
                      <span>{{ formatNumber(item.fromValueReal) }}</span
                      ><span class="ml-2 mr-2">/</span>

                      <span
                        :class="
                          item.toValueReal > item.fromValueReal ? 'text-green' : item.toValueReal == item.fromValueReal ? 'text-info' : 'text-red'
                        "
                        >{{ formatNumber(item.toValueReal) }}
                        <v-icon
                          size="17"
                          class="d-none d-md-inline"
                          :class="
                            item.toValueReal > item.fromValueReal ? 'text-green' : item.toValueReal == item.FromValueReal ? 'text-info' : 'text-red'
                          "
                          >{{
                            item.toValueReal > item.fromValueReal
                              ? "mdi mdi-arrow-up"
                              : item.fromValueReal == item.toValueReal
                              ? ""
                              : "mdi mdi-arrow-down"
                          }}
                        </v-icon>
                      </span>
                    </td>
                    <td>{{ item.createdAt }}</td>
                    <td>
                      <v-btn size="small" variant="text" @click="remove(item)" icon="mdi mdi-delete" color="red"></v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <v-progress-circular class="d-block ma-auto mb-3 mt-10" :size="80" v-else color="amber" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
        <div class="v-card-actions">
          <v-btn color="secondary" variant="tonal" @click="isShowDialogLogMain = false"><v-icon>mdi mdi-close</v-icon></v-btn>
          <span class="text-cyan">*{{ $t("logKeep", { count: 1000 }) }}</span>
          <v-spacer></v-spacer>
          <v-pagination
            v-if="walletStore$.logs.pagination.last_page > 1"
            style="width: 100%"
            v-model="filter.page"
            :length="walletStore$.logs.pagination.last_page"
          ></v-pagination>
          <v-spacer></v-spacer>
          <v-btn
            @click="submit()"
            :disabled="
              parseInt(walletStore$.money_real) == removeAllSpecialCharacter(form.money_real) &&
              parseInt(walletStore$.money_total) == removeAllSpecialCharacter(form.money_total)
            "
            type="submit"
            variant="flat"
            class="float-right"
            color="info"
          >
            <v-icon>mdi mdi-sync</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { useAuthStore } from "@/stores/authStore";
import { useConfigStore } from "@/stores/configStore";
import { useWalletStore } from "@/stores/walletStore";
import { useToast } from "vue-toastification";
import { useTheme } from "vuetify/lib/framework.mjs";
import ElementTotalMoney from "../elements/ElementTotalMoney.vue";
import walletService from "@/services/wallet.service";
import logService from "@/services/log.service";
import { useMoneyStore } from "@/stores/moneyStore";
export default {
  name: "LogMain",
  setup: () => ({
    auth$: useAuthStore(),
    toast$: useToast(),
    configStore$: useConfigStore(),
    theme$: useTheme(),
    walletStore$: useWalletStore(),
    moneyStore$: useMoneyStore(),
  }),
  components: { ElementTotalMoney },
  props: { isShowDialogLogMain: Boolean },
  data() {
    return {
      isShow: this.isShowDialogLogMain,
      from: "",
      to: "",
      query: "",
      filter: {
        page: 1,
        state: "",
      },
      loading: true,
      form: {
        money_total: 0,
        money_real: 0,
      },
      desserts: [],
      page: 1,
      logs: {
        data: [],
        pagination: {
          current_page: 1,
          total: 0,
          per_page: 10,
          last_page: 1,
        },
      },
    };
  },
  created() {
    // this.$on('loadLogEmit', () => {
    //     console.log("teoem")
    // })
  },
  mounted() {
    this.form.money_real = this.walletStore$.money_real;
    this.form.money_total = this.walletStore$.money_total;

    this.loadLogs();
  },
  methods: {
    async remove(item) {
      var _this = this;
      this.$swal({
        title: this.$t("swalSureField", { action: this.$t("delete") }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("no"),
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var removeRequest = await logService.delete(item.id);
          if (removeRequest.success) {
            this.configStore$.resetFilterLog();
            this.loadLogs();
            this.toast$.success(this.$t("fieldSuccess", { field: this.$t("log"), action: this.$t("actionRemove") }));
          }
        }
      });
    },
    async loadLogs() {
      this.loading = true;
      await this.walletStore$.loadLogs(this.configStore$.filter.log);
      this.loading = false;

      // this.loading = true;
      // var logsRequest = await logService.logs(this.configStore$.filter.log);
      // if (logsRequest.success) {
      //   this.mappingResponse(logsRequest.logs, this.logs);
      //   console.log(this.logs)
      //   this.loading = false;
      // }
    },
    submit() {
      var _this = this;
      this.$swal({
        title: this.$t("swalSure"),
        text: this.$t("changeBalanceAccount"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("no"),
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var objectUpdate = {
            moneyTotal: this.removeAllSpecialCharacter(this.form.money_total),
            moneyReal: this.removeAllSpecialCharacter(this.form.money_real),
          };
          var updateBalanceRequest = await walletService.updateUserBalance(objectUpdate);
          if (updateBalanceRequest.success) {
            this.toast$.success(this.$t("fieldSuccess", { field: this.$t("balanceAccount"), action: this.$t("actionUpdated") }));
            // this.moneyStore$.loadTransaction(this.walletStore$.walletSelected.id, null, this.$route.params.transactionId);
            this.walletStore$.loadWalletsAndUser();
            this.loadLogs();
            this.isShow = false;
          } else {
            this.toast$.error(this.$t("someThingWrong"));
          }
        }
      });
    },
    filterTransaction(state, item = null) {
      this.filter.modal = "log";
      this.filter.page = 1;
      if (state == "to") {
        if (this.to) {
          this.filter.to = this.to;
        } else {
          delete this.filter.to;
        }
      }

      if (state == "inflow" || state == "outflow" || state == "balance") {
        if (this.configStore$.filter.log.state == state) {
          this.filter.state = state;
          delete this.filter.state;
        } else {
          this.filter.state = state;
        }
      }

      if (state == "from") {
        if (this.from) {
          this.filter.from = this.from;
        } else {
          delete this.filter.from;
        }
      }
      if (state == "query") {
        if (this.query) {
          this.filter.query = this.query;
        } else {
          delete this.filter.query;
        }
      }

      this.configStore$.setFilterLog(this.filter);
      this.loadLogs();
      // if (this.$route.params.transactionId) {
      //   return this.$router.push({
      //     name: this.$route.name,
      //     params: { transactionId: this.$route.params.transactionId, walletId: this.$route.params.walletId },
      //     query: this.filter,
      //   });
      // } else if (this.$route.params.walletId) {
      //   return this.$router.push({ name: this.$route.name, params: { walletId: this.$route.params.walletId }, query: this.filter });
      // } else {
      //   return this.$router.push({ name: this.$route.name, query: this.filter });
      // }
    },
  },
  watch: {
    "$route.query": function (new_value) {
      if (new_value.modal == "log") {
        this.loadLogs();
      }
    },
    "filter.page": function (new_value) {
      var temp = this.configStore$.filter.log;
      if (temp.page) {
        temp.page = new_value;
        this.configStore$.setFilterLog(temp);
      } else {
        this.configStore$.setFilterLog({ page: new_value });
      }

      this.loadLogs();
    },
    "form.money_total": function (new_value) {
      this.form.money_total = this.formatCurrency(new_value);
    },
    "form.money_real": function (new_value) {
      this.form.money_real = this.formatCurrency(new_value);
    },
    isShow: function (new_value) {
      if (!new_value) {
        this.$emit("closeDiaLogMain");
      }
    },
    isShowDialogLogMain: function (new_value) {
      this.isShow = new_value;
      this.form.money_real = this.walletStore$.money_real;
      this.form.money_total = this.walletStore$.money_total;
    },
  },
};
</script>
<style scoped></style>
