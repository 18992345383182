<template lang="">
  <v-row v-if="auth$.checkRole(ROLE_ICON_MAIN)" class="justify-center mt-2">
    <v-col cols="12" lg="10">
      <v-card>
        <v-card-title class="">
          <span>{{ $t("adminUserList") }}</span>
          <v-btn
            v-if="auth$.checkRole(ROLE_USER_ADD)"
            @click="$router.push({ name: ROUTER_ADMIN_USER_ADD })"
            class="float-right"
            color="green"
            variant="text"
            icon="mdi mdi-plus"
          ></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field v-model="from" @change="handleFilter('from')" :label="$t('MoneyTransactionFrom')" type="date"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="to" @change="handleFilter('to')" :label="$t('MoneyTransactionTo')" type="date"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="query" @change="handleFilter('query')" :label="$t('MoneyTransactionSearch') + '...'" type="text"></v-text-field>
            </v-col>
            <!-- <v-col cols="12">
              <v-chip color="red" class="hover-pointer mr-1 ml-1" @click="handleFilter('admin')" :class="filter.state == 'admin' ? 'rounded-shaped border-primary-solid' : ''">{{
                $t("admin")
              }}</v-chip>
              <v-chip color="info" class="hover-pointer mr-1 ml-1" @click="handleFilter('manager')" :class="filter.state == 'manager' ? 'rounded-shaped border-primary-solid' : ''">{{
                $t("adminRoleManager")
              }}</v-chip>
              <v-chip color="secondary" class="hover-pointer mr-1 ml-1" @click="handleFilter('normal')" :class="filter.state == 'normal' ? 'rounded-shaped border-primary-solid' : ''">{{
                $t("adminRoleNormal")
              }}</v-chip>
            </v-col> -->
          </v-row>
          <v-table hover v-if="!loading" block>
            <thead>
              <tr>
                <th class="text-left">{{ $t("adminTdIcon") }}</th>
                <th class="text-left">{{ $t("adminTdName") }}</th>
                <th class="text-left">{{ $t("adminTdPhone") }}</th>
                <th class="text-left">Email</th>
                <th class="text-left">{{ $t("adminTdRole") }}</th>
                <th class="text-left">{{ $t("adminTdCreated") }}</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="item.super ? 'bg-red-lighten-4' : item.default ? '' : 'bg-deep-purple-lighten-5'"
                v-for="item in users.data"
                :key="item.name"
              >
                <td class="text-left">
                  <v-img width="50" height="50" :src="`${baseUrl}/images/icons/${item?.icon ? item.icon.value : 'icon_153.png'}`"> </v-img>
                </td>
                <td :class="item.super ? 'text-red-darken-1 font-weight-bold' : ''" class="text-left">{{ item.name }}</td>
                <td class="text-left">{{ item.phone }}</td>
                <td class="text-left">{{ item.email }}</td>
                <td class="text-left">
                  <v-badge v-if="item.roles.length > 0" class="hover-pointer" color="primary" :content="item.roles.length">
                    <v-icon>mdi-hand-back-left</v-icon>
                    <v-tooltip v-if="item.roles.length > 0" activator="parent" location="top">
                      <span class="mx-2" v-for="role in item.roles">{{ role.role.name }}</span>
                    </v-tooltip>
                  </v-badge>
                  <v-icon v-else>mdi-hand-back-left</v-icon>
                </td>
                <td class="text-left">{{ dateFormatSaveMoney(item.created_at) }}</td>
                <td class="text-left">
                  <v-btn
                    v-if="auth$.id != item.id && auth$.checkRole(ROLE_USER_PASSWORD)"
                    @click="view(item)"
                    class="ma-1"
                    color="primary"
                    icon="mdi-eye"
                    variant="outlined"
                    size="small"
                  ></v-btn>
                  <v-btn
                    v-if="auth$.id != item.id && auth$.checkRole(ROLE_USER_DELETE)"
                    @click="remove(item)"
                    class="ma-1"
                    color="error"
                    icon="mdi-delete"
                    variant="outlined"
                    size="small"
                  ></v-btn>
                  <v-btn
                    @click="edit(item)"
                    v-if="auth$.checkRole(ROLE_USER_EDIT)"
                    :variant="'outlined'"
                    color="info"
                    icon="mdi-pencil"
                    class="ma-1"
                    size="small"
                  ></v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
          <LoadingAdmin v-else></LoadingAdmin>
          <v-pagination
            v-if="users.pagination.last_page > 1"
            style="width: 100%"
            v-model="filter.page"
            :length="users.pagination.last_page"
          ></v-pagination>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import userService from "@/services/admins/user.service";
import LoadingAdmin from "../elements/LoadingAdmin.vue";
import { useAuthStore } from "@/stores/authStore";

export default {
  name: "UserList",
  setup: () => ({
    auth$: useAuthStore(),
  }),
  components: {
    LoadingAdmin,
  },
  data() {
    return {
      loading: true,
      from: "",
      to: "",
      query: "",
      filter: {
        page: 1,
        state: "",
      },
      users: {
        data: [],
        pagination: {
          current_page: 1,
          total: 0,
          per_page: 10,
          last_page: 1,
        },
      },
    };
  },
  created() {},
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      var response = await userService.users(this.filter);
      if (response.success) {
        this.mappingResponse(response.users, this.users);
      }

      this.users.data.map((item) => {
        if (item.roles.length > 0) {
          item.roles.map((role) => {
            if (role.role.code == "super") {
              item.super = true;
            } else if (role.role.default == 1) {
              item.default = true;
            }
          });
        }
      });

      this.loading = false;
    },
    async remove(item) {
      var _this = this;
      this.$swal({
        title: this.$t("swalSureField", { action: this.$t("delete") }),
        text: this.$t("adminUserConfirmDelete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("no"),
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var res = await userService.remove(item.id);
          if (res.success) {
            _this.load();
            this.toast$.success(this.$t("fieldSuccess", { field: this.$t("SideBarAccount"), action: this.$t("actionRemove") }));
          } else {
            this.toast$.error(saveRes.message);
          }
        }
      });
    },

    edit(item) {
      this.$router.push({ name: this.ROUTER_ADMIN_USER_EDIT, params: { id: item.id } });
    },
    async view(item) {
      var res = await userService.viewPassword(item.id);
      if (res.success) {
        alert(res.password);
      } else {
        this.toast$.error("Empty");
      }
    },
    handleFilter(state, item = null) {
      this.filter.page = 1;
      if (state == "to") {
        if (this.to) {
          this.filter.to = this.to;
        } else {
          delete this.filter.to;
        }
      }

      if (state == "admin" || state == "normal" || state == "manager") {
        if (this.filter.state == state) {
          this.filter.state = state;
          delete this.filter.state;
        } else {
          this.filter.state = state;
        }
      }

      if (state == "from") {
        if (this.from) {
          this.filter.from = this.from;
        } else {
          delete this.filter.from;
        }
      }
      if (state == "query") {
        if (this.query) {
          this.filter.query = this.query;
        } else {
          delete this.filter.query;
        }
      }
      this.load();
    },
  },
  watch: {
    "filter.page": function () {
      this.load();
    },
  },
};
</script>
<style lang=""></style>
