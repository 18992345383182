<template lang="">
  <v-container :fluid="true" class="mt-2">
    <div class="justify-center">
      <v-row class="mt-2 ml-1 mr-1 justify-center">
        <v-slide-y-transition>
          <v-col cols="12" class="justify-center d-none" :class="$route.params.walletId ? `${auth$.setting.isMoneyView == 1 ? 'd-lg-block' : 'd-none'}` : 'd-block'" lg="4">
            <MoneyWallet :style="displayFixWallets()" @selectWallet="selectWallet($event)"> </MoneyWallet
          ></v-col>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <v-col v-if="$route.params.walletId" cols="12" lg="8">
            <MoneyTransaction v-if="!isShowTransactionPanel"></MoneyTransaction>
          </v-col>
        </v-slide-y-transition>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import MoneyTransaction from "./MoneyTransaction.vue";
import MoneyTransactionDetail from "./MoneyTransactionDetail.vue";
import MoneyWallet from "./MoneyWallet.vue";
import { useAuthStore } from "@/stores/authStore";
import { useWalletStore } from "@/stores/walletStore";
import { useToast } from "vue-toastification";
import { useDisplay, useTheme } from "vuetify/lib/framework.mjs";
import moneyService from "@/services/money.service.js";
import { useMoneyStore } from "@/stores/moneyStore";
import { ROUTER_MONEY, ROUTER_WALLET } from "@/useConfig";
export default {
  setup: () => ({
    theme$: useTheme(),
    auth$: useAuthStore(),
    display$: useDisplay(),
    toast$: useToast(),
    walletStore$: useWalletStore(),
    moneyStore$: useMoneyStore(),
  }),
  components: { MoneyTransaction, MoneyTransactionDetail, MoneyWallet },
  name: "MoneyMain",
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      isShowPanelWallet: 0,
      isShowTransactionPanel: true,
      isShowTransactionDetailPanel: false,
      categories: [],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.loadDataCreator();
    if (!this.walletStore$.walletSelected?.id) {
      this.$router.push({ name: ROUTER_WALLET, query: { showForm: true } });
    } else {
      if (this.$route.params.walletId) {
        this.$router.push({
          name: ROUTER_MONEY,
          params: { walletId: this.walletStore$.walletSelected.id },
          query: { ...this.$route.query },
        });
      } else {
        this.$router.push({
          name: ROUTER_MONEY,
        });
      }
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    displayFixWallets() {
      let width = 0;
      let css = "";
      if (this.display$.lgAndUp && !this.display$.xlAndDown) {
        width = 30;
        css = `position:fixed;width:${width}%`;
        return css;
      } else if (this.display$.xlAndUp && !this.display$.lgAndDown) {
        width = 31;
        css = `position:fixed;width:${width}%`;
        return css;
      }
      return "";
    },
    async loadTransactions(event) {
      this.loadTransaction();
    },

    async loadDataCreator() {
      this.isShowTransactionPanel = true;
      var categoryRequest = await moneyService.categories();
      if (categoryRequest.success) {
        this.categories = categoryRequest.categories;
      }
      this.isShowTransactionPanel = false;
    },
    selectWallet(item) {
      this.$router.push({ name: "money", params: { walletId: item.id } });
    },
  },
  watch: {},
};
</script>
<style lang=""></style>
