import Api from "@/utils/axios";

export default {
  roles() {
    return Api.post("/admin/roles");
  },
  rolesActive() {
    return Api.post("/admin/roles/rolesActive");
  },
  byId(data) {
    return Api.post("/admin/roles/byId",data);
  },
  add(data) {
    return Api.post("/admin/roles/save",data);
  },
  update(data) {
    return Api.post("/admin/roles/update",data);
  },
  delete(id) {
    return Api.post("/admin/roles/delete",{id:id});
  },

};
