<template lang="">
  <v-card>
    <v-card-title>
      <div class="d-flex justify-space-between">
        <div class="justify-space-center" style="line-height: 42px">
          <span class="ml-5"
            ><span>{{ module.title }}{{ module?.name }}</span></span
          >
        </div>
        <v-btn variant="text" class="float-right" @click="$emit('eventDiaglogAdd', false)" color="secondary" icon="mdi mdi-close"></v-btn>
      </div>
      <v-divider></v-divider>
    </v-card-title>
    <v-form class="pa-4" @submit.prevent="submit()">
      <v-card-text>
        <v-row class="pa-2">
          <v-col cols="12" class="justify-center">
            <v-autocomplete
              :disabled="module.edit"
              v-model="moduleSelected"
              :label="$t('moduleName')"
              :items="modules"
              item-value="id"
              :item-title="configStore.lang == LANG_EN ? 'name_en' : 'name'"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="6" class="justify-center">
            <v-text-field
              v-model="v$.form.code.$model"
              :error-messages="v$.form.code.required.$invalid && v$.form.code.$dirty ? $t('validRequired', { field: $t('moduleCode') }) : ''"
              @input="v$.form.code.$touch()"
              @blur="v$.form.code.$touch()"
              name="code"
              readonly
              disabled
              :label="$t('moduleCode') + '*'"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="justify-center">
            <v-text-field
              v-model="v$.form.code.$model"
              :error-messages="
                v$.form.page_url.required.$invalid && v$.form.page_url.$dirty ? $t('validRequired', { field: $t('modulePageURL') }) : ''
              "
              @input="v$.form.page_url.$touch()"
              @blur="v$.form.page_url.$touch()"
              name="page_url"
              readonly
              disabled
              :label="$t('modulePageURL') + '*'"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="justify-center">
            <v-text-field
              v-model="v$.form.icon.$model"
              :error-messages="v$.form.icon.required.$invalid && v$.form.icon.$dirty ? $t('validRequired', { field: $t('moduleIcon') }) : ''"
              @input="v$.form.icon.$touch()"
              @blur="v$.form.icon.$touch()"
              name="icon"
              :append-inner-icon="form.icon"
              :label="$t('moduleIcon') + '*'"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="justify-center">
            <v-text-field
              v-model="v$.form.position.$model"
              :error-messages="
                v$.form.position.required.$invalid && v$.form.position.$dirty ? $t('validRequired', { field: $t('moduleMenuIndex') }) : ''
              "
              @input="v$.form.position.$touch()"
              @blur="v$.form.position.$touch()"
              name="position"
              :label="$t('moduleMenuIndex') + '*'"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="justify-center">
            <v-switch v-model="form.active" color="info" :label="` ${$t('moduleMenuActive')}`"></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <br />
      <v-card-actions>
        <v-btn  @click="remove()" variant="outlined" v-if="module.type == 'role' && auth$.checkRole(ROLE_MODULE_DELETE_ROLE) " color="error"><v-icon>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="module.edit && (auth$.checkRole(ROLE_MODULE_EDIT_MODULE) || auth$.checkRole(ROLE_MODULE_EDIT_ROLE) || auth$.checkRole(ROLE_MODULE_EDIT_FEATURE))" type="submit" variant="flat" color="info"><v-icon>mdi mdi-pencil</v-icon></v-btn>
        <v-btn v-if="!module.edit  && (auth$.checkRole(ROLE_MODULE_ADD_MODULE) || auth$.checkRole(ROLE_MODULE_ADD_ROLE) || auth$.checkRole(ROLE_MODULE_ADD_FEATURE))" type="submit" variant="flat" color="success"><v-icon>mdi mdi-plus</v-icon></v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import userService from "@/services/admins/user.service";
import { throwStatement } from "@babel/types";
import LoadingAdmin from "../elements/LoadingAdmin.vue";
import permissionService from "@/services/permission.service";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification";
import { required } from "@vuelidate/validators";
import { ModuleLevel2, ModuleLevel3, Modules } from "./moduleConstant";
import { useConfigStore } from "@/stores/configStore";

export default {
  name: "ModuleAdd",
  setup: () => ({
    v$: useVuelidate(),
    toast$: useToast(),
    configStore: useConfigStore(),
  }),
  props: {
    module,
  },
  components: {
    LoadingAdmin,
  },
  data() {
    return {
      flag: false,
      items: "",
      modules: [],
      moduleSelected: "",
      moduleObject: "",
      permissions: [],
      form: {
        icon: "",
        name: "",
        code: "",
        name_en: "",
        menu_parent_id: null,
        page_url: "",
        active: true,
        position: 10,
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        code: {
          required,
        },
        icon: {
          required,
        },
        position: {
          required,
        },
        page_url: {
          required,
        },
        active: {},
      },
    };
  },
  created() {},
  mounted() {
    this.loadModules();
  },
  methods: {
    loadModules() {
      this.reset();

      if (this.module.type == "module") {
        Modules.map((item) => {
          let checkExistingModule = this.module.permissions.find((x) => x.code == item.code);
          if (!checkExistingModule || this.module.edit) {
            this.modules.push(item);
          }
        });
      } else if (this.module.type == "feature") {
        let permissionLV2 = [];
        this.module.permissions.forEach((item) => {
          if (item.children.length > 0) {
            item.children.forEach((feature) => permissionLV2.push(feature));
          }
        });
        ModuleLevel2.map((item) => {
          let checkExistingModule = permissionLV2.find((x) => x.code == item.code);
          if (!checkExistingModule || this.module.edit) {
            if (this.module.module.code == "money") {
              if (item.code.search("transaction") != -1) {
                this.modules.push(item);
              }
            } else {
              if (item.code.search(this.module.module.code) != -1) {
                this.modules.push(item);
              }
            }
          }
        });
        this.form.menu_parent_id = this.module.module.id;
      } else if (this.module.type == "permission") {
        let permissionLV3 = [];
        this.module.permissions.forEach((item) => {
          if (item.children.length > 0) {
            item.children.forEach((feature) => {
              if (feature.children.length > 0) {
                feature.children.forEach((permission) => [permissionLV3.push(permission)]);
              }
            });
          }
        });
        ModuleLevel3.map((item) => {
          let checkExistingModule = permissionLV3.find((x) => x.code == item.code);
          if (!checkExistingModule || this.module.edit) {
            if (item.code.search(this.module.module.code) != -1) {
              this.modules.push(item);
            }
          }
        });

        this.form.menu_parent_id = this.module.module.id;
      }

      if (this.module.edit) {
        this.moduleObject = this.module.module;
        this.moduleSelected = this.moduleObject;
        this.form.code = this.module.module.code;
        this.form.name = this.module.module.name;
        this.form.page_url = this.module.module.page_url;
        this.form.icon = this.module.module.icon;
        this.form.name_en = this.module.module.name_en;
        this.form.active = this.module.module.active == 1 ? true : false;
        this.form.position = this.module.module.menu_index;
      }
    },
    handleEventOk() {},
    async submit() {
      var _this = this;
      const isFormCorrect = await this.v$.form.$validate();
      if (!isFormCorrect) {
        return;
      }
      let formModule = {
        ...this.form,
        name_en: this.moduleObject.name_en,
      };
      let request;
      if (this.module.edit) {
        formModule.id = this.moduleObject.id;
        request = await permissionService.update(formModule);
      } else {
        request = await permissionService.save(formModule);
      }
      if (request.success) {
        this.reset();
        this.$emit("eventDiaglogAdd", `${this.module.edit ? "updated" : "created"}`);
      }
    },
    async remove() {
      let deleteRequest = await permissionService.delete(this.module.module.id);
      if (deleteRequest.success) {
        this.$emit("eventDiaglogAdd", `${this.module.edit ? "updated" : "created"}`);
        this.reset();
      }
    },
    reset() {
      this.form = {
        icon: "",
        name: "",
        code: "",
        name_en: "",
        menu_parent_id: null,
        page_url: "",
        active: true,
        position: 10,
      };
    },
  },
  watch: {
    moduleSelected: function (new_value, old_value) {
      if (new_value) {
        const findModule = this.modules.find((x) => x.id == new_value);
        if (findModule) {
          this.moduleObject = findModule;
          this.form.code = findModule.code;
          this.form.name = findModule.name;
          this.form.page_url = findModule.page_url;
          this.form.icon = findModule.icon;
          this.form.name_en = findModule.name_en;
        }
      }
    },
  },
};
</script>
<style>
.v-messages__message {
  color: red;
  font-weight: 400;
}
</style>
