import {
  ROUTER_ADMIN,
  ROUTER_ADMIN_CATEGORY,
  ROUTER_ADMIN_PERMISSION,
  ROUTER_ADMIN_ICON,
  ROUTER_ADMIN_ICON_ADD,
  ROUTER_ADMIN_ICON_EDIT,
  ROUTER_ADMIN_USER,
  ROUTER_ADMIN_USER_ADD,
  ROUTER_ADMIN_USER_EDIT,
  ROUTER_ADMIN_ROLE,
  ROUTER_ADMIN_MODULES,
  ROUTER_ADMIN_ROLE_EDIT,
  ROUTER_ADMIN_ROLE_ADD,
} from "./useConfig";
import AdminUI from "@/pages/admins/AdminUI.vue";
import AdminCategoryPage from "@/pages/admins/AdminCategoryPage.vue";
import AdminUserPage from "@/pages/admins/AdminUserPage.vue";
import AdminIconPage from "@/pages/admins/AdminIconPage.vue";

import UserList from "@/pages/admins/user/UserList";
import UserAdd from "@/pages/admins/user/UserAdd";
import UserEdit from "@/pages/admins/user/UserEdit";

import IconList from "@/pages/admins/icon/IconList";
import IconAdd from "@/pages/admins/icon/IconAdd";
import IconEdit from "@/pages/admins/icon/IconEdit";
import AdminRolePage from "./pages/admins/AdminRolePage.vue";
import AdminModulePage from "./pages/admins/AdminModulePage.vue";
import RoleAdd from "./pages/admins/roles/RoleAdd.vue";
import RoleEdit from "./pages/admins/roles/RoleEdit.vue";

export const routesAdmin = [
  {
    path: `/${ROUTER_ADMIN}`,
    name: ROUTER_ADMIN,
    component: AdminUI,
    children: [
      {
        path: ROUTER_ADMIN_USER,
        name: ROUTER_ADMIN_USER,
        component: AdminUserPage,
        children: [
          {
            path: `add`,
            name: ROUTER_ADMIN_USER_ADD,
            component: UserAdd,
          },
          {
            path: `${ROUTER_ADMIN_USER}/edit/:id?`,
            name: ROUTER_ADMIN_USER_EDIT,
            component: UserEdit,
          },
        ],
      },
      {
        path: ROUTER_ADMIN_ICON,
        name: ROUTER_ADMIN_ICON,
        component: AdminIconPage,
        children: [
          {
            path: `add`,
            name: ROUTER_ADMIN_ICON_ADD,
            component: IconAdd,
          },
          {
            path: `${ROUTER_ADMIN_ICON}/edit/:id?`,
            name: ROUTER_ADMIN_ICON_EDIT,
            component: IconEdit,
          },
        ],
      },

      {
        path: ROUTER_ADMIN_CATEGORY,
        name: ROUTER_ADMIN_CATEGORY,
        component: AdminCategoryPage,
      },
      {
        path: ROUTER_ADMIN_MODULES,
        name: ROUTER_ADMIN_MODULES,
        component: AdminModulePage,
      },
      {
        path: ROUTER_ADMIN_ROLE,
        name: ROUTER_ADMIN_ROLE,
        component: AdminRolePage,
        children: [
          {
            path: `add`,
            name: ROUTER_ADMIN_ROLE_ADD,
            component: RoleAdd,
          },
          {
            path: `edit/:id?`,
            name: ROUTER_ADMIN_ROLE_EDIT,
            component: RoleEdit,
          },
        ],
      },
    ],
    meta: {
      titleI18n: "adminPage",
      admin: true,
    },
  },
  //   {
  //     path: `/${ROUTER_ADMIN}/${ROUTER_ADMIN_USER}`,
  //     name: ROUTER_ADMIN_USER,
  //     component: AdminUserPage,
  //     meta: {
  //       titleI18n: "adminPage",
  //       admin: true,
  //     },
  //   },
  //   {
  //     path: `/${ROUTER_ADMIN}/${ROUTER_ADMIN_CATEGORY}`,
  //     name: ROUTER_ADMIN_CATEGORY,
  //     component: AdminCategoryPage,
  //     meta: {
  //       titleI18n: "adminPage",
  //       admin: true,
  //     },
  //   },
];
