import Api from "@/utils/axios";

export default {
  register(data) {
    return Api.post("auth/register", data);
  },
  updateSetting(data) {
    return Api.post("/users/updateSetting", {...data});
  },
  updateConfig(key,value) {
    return Api.post("/user/doAction", {action:'update-config',key:key,value:value});
  },
  login(data) {
    return Api.post("auth/login", data);
  },
  loadUser() {
    return Api.post("/user/doAction",{action:'user'});
  },

  logout(token) {
    return Api.post("auth/logout", { token: token });
  },
  changeInformationAccount(body) {
    return Api.post("/users/changeInformationAccount", {...body });
  },
  me(){
    return Api.get('users/me');
  },
  refresh(){
    return Api.get('auth/refresh');
  }
};
