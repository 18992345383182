<template>
  <IconList v-if="$route.name == ROUTER_ADMIN_ICON && auth$.checkRole(ROLE_ICON_MAIN)"></IconList>
  <RouterView v-else></RouterView>
</template>
<script>
import MoneyUI from "@/components/layouts/MoneyUI.vue";
import IconList from "./icon/IconList.vue";
import { useAuthStore } from "@/stores/authStore";
import { ROLE_ICON } from "@/useConfig";
export default {
  name: "AdminIconPage",
  setup: () => ({
    auth$: useAuthStore(),
  }),
  components: {
    MoneyUI,
    IconList,
  },
};
</script>
<style lang=""></style>
