import Api from "@/utils/axios";

export default {
  users(filter = null) {
    return Api.post("/admin/users", { ...filter });
  },
  user(id) {
    return Api.post("/admin/users/user", { id: id });
  },
  save(data) {
    return Api.post("/admin/users/save", { ...data });
  },
  update(data) {
    return Api.post("/admin/users/update", { ...data });
  },
  viewPassword(id) {
    return Api.post("/admin/users/viewPassword", { id: id });
  },
  remove(id) {
    return Api.post("/admin/users/delete", { id: id });
  },
};
